import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { GetMonthTarif } from "../../services/query/get-month-tarif";
import { get } from "lodash";
import { GetYearTarif } from "./../../services/query/get-year-tarif";
import SubscribeModal from "./Components/SubscribeModal";
import SubscribeFreeModal from "./Components/SubscribeFreeModal";
import { GetUserInfo } from "../../services/query/get-user-info";
import styled from "styled-components";
import True from "./Components/true";


/**************          modal  ************************ */

function ModalTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`action-tabpanel-${index}`}
      aria-labelledby={`action-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </Typography>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`action-tabpanel-${index}`}
      aria-labelledby={`action-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `action-tab-${index}`,
    "aria-controls": `action-tabpanel-${index}`,
  };
}

const ModalProPage = () => {

  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeIndex = (index) => {
    setValue(index);
  };
  const getMonthTarif = GetMonthTarif();
  const getYearTarif = GetYearTarif();
  const first = getMonthTarif?.data?.filter(item => item?.is_monthly_location === 1)
  const second = getMonthTarif?.data?.filter(item => item?.is_monthly_location === 2)
  const third = getMonthTarif?.data?.filter(item => item?.is_monthly_location === 3)
  const secondYear = getYearTarif?.data?.filter(item => item?.is_yearly_location === 2)
  const thirdYear = getYearTarif?.data?.filter(item => item?.is_yearly_location === 3)
  // ******************************************
  const [tarifPrice, setTarifPrice] = useState("");
  localStorage.setItem("tarifPrice", tarifPrice)
  const [tarifName, setTarifName] = useState("");
  localStorage.setItem("tarifName", tarifName)
  const [tarifId, setTarifId] = useState("");
  localStorage.setItem("tarifId", tarifId)
  const [currency, setCurrency] = useState("");
  localStorage.setItem("currency", currency)
  const [activeStatus, setActiveStatus] = useState("");
  localStorage.setItem("active", activeStatus)
  const getUserInfo = GetUserInfo(localStorage.getItem("id"));
console.log(getUserInfo?.data?.status==="PREMIUM",getUserInfo?.data?.is_monthly===true)
console.log(getUserInfo?.data?.status==="PREMIUM" && getUserInfo?.data?.is_monthly===true)
  return (
    <div style={{ padding: "25px 0" }}>
      <Box sx={{}}>
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            variant="fullWidth"
            aria-label="action tabs example">
            <Tab label="Oylik" {...a11yProps(0)} />
            <Tab label="Yillik" {...a11yProps(1)} />
          </Tabs>
        </AppBar>
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={value}
          onChangeIndex={handleChangeIndex}>
          <ModalTabPanel value={value} index={0} dir={theme.direction}>
            <Swiper
              slidesPerView={3}
              spaceBetween={30}
              slidesPerGroup={1}
              loop={true}
              loopFillGroupWithBlank={true}
              navigation={true}
              modules={[Navigation]}
              className="premium-swiper mySwiper">
              <SwiperSlide>
                <div className={`premium-section ${(getUserInfo?.data?.status==="FREE"  && getUserInfo?.data?.is_monthly===true)  ? `premium-section-active`:""}`}>
                  <div className="pre-top">
                    <h3 className={`pre-title ${(getUserInfo?.data?.status==="FREE" && getUserInfo?.data?.is_monthly===true) ? `modal-status-active`:""}`} >{get(first, "[0].name")}</h3>
                    <span className="premium-span">
                      <p className={`premium-price ${(getUserInfo?.data?.status==="FREE" && getUserInfo?.data?.is_monthly===true) ? `premium-info-active`:""}`} >{get(first, "[0].currency")} {get(first, "[0].price")}</p>
                      <p className= {`premium-subtext ${(getUserInfo?.data?.status==="FREE" && getUserInfo?.data?.is_monthly===true) ? `premium-info-active`:""}`}>/ Oylik</p>
                    </span>
                    <p className={`premium-info ${(getUserInfo?.data?.status==="FREE" && getUserInfo?.data?.is_monthly===true) ? `premium-info-active`:""}`}>
                      Bu bizning bepul tarifimiz. O'z ichiga oladi:
                    </p>
                  </div>
                  <ul className="pre-list">
                    <li className="pre-item">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 11.4182L9.68571 17.8L21 7" stroke={` ${(getUserInfo?.data?.status==="FREE" && getUserInfo?.data?.is_monthly===true) ? `white`:"blueviolet"}`} stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
                      <p className={`pre-subinfo ${(getUserInfo?.data?.status==="FREE" && getUserInfo?.data?.is_monthly===true) ? `premium-info-active`:""}`}>
                        {get(first, "[0].description1")}
                      </p>
                    </li>
                    <li className="pre-item">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 11.4182L9.68571 17.8L21 7" stroke={` ${(getUserInfo?.data?.status==="FREE" && getUserInfo?.data?.is_monthly===true) ? `white`:"blueviolet"}`} stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
                      <p className={`pre-subinfo ${(getUserInfo?.data?.status==="FREE" && getUserInfo?.data?.is_monthly===true) ? `premium-info-active`:""}`}>
                        {get(first, "[0].description2")}
                      </p>
                    </li>
                    <li className="pre-item">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 11.4182L9.68571 17.8L21 7" stroke={` ${(getUserInfo?.data?.status==="FREE" && getUserInfo?.data?.is_monthly===true) ? `white`:"blueviolet"}`} stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
                      <p className={`pre-subinfo ${(getUserInfo?.data?.status==="FREE" && getUserInfo?.data?.is_monthly===true) ? `premium-info-active`:""}`}>
                        {get(first, "[0].description3")}
                      </p>
                    </li>
                  </ul>

                  {/* <div onClick={() => setCurrency(get(first, "[0].currency"))}>
                    <div onClick={() => setTarifId(get(first, "[0].id"))}>
                      <div onClick={() => setTarifName(get(first, "[0].name"))}>
                        <div onClick={() => setTarifPrice(get(first, "[0].price"))}>
                          <SubscribeFreeModal />
                        </div>
                      </div>
                    </div>
                  </div> */}
                  {(getUserInfo?.data?.status==="FREE" && getUserInfo?.data?.is_monthly===true) ? 
                  <button className="subscribe-choose">Siz FREE tarifdasiz</button>:             
                    <div onClick={() => setCurrency(get(first, "[0].currency"))}>
                   <div onClick={() => setTarifId(get(first, "[0].id"))}>
                     <div onClick={() => setTarifName(get(first, "[0].name"))}>
                       <div onClick={() => setTarifPrice(get(first, "[0].price"))}>
                         <SubscribeModal  />
                       </div>
                     </div>
                   </div>
                 </div>}
                  {/* <Link to={`/${username}`}>
                          <button className="pre-btn">Tarifni tanlash</button>
                        </Link> */}
                </div>
              </SwiperSlide>
              <SwiperSlide>
              <div className={`premium-section ${(getUserInfo?.data?.status==="PREMIUM" && getUserInfo?.data?.is_monthly===true) ? `premium-section-active`:""}`}>
                  <div className="pre-top">
                    <h3 className={`pre-title ${(getUserInfo?.data?.status==="PREMIUM" && getUserInfo?.data?.is_monthly===true) ? `modal-status-active`:""}`}>{get(second, "[0].name")}</h3>
                    <span className="premium-span">
                    <p className={`premium-price ${(getUserInfo?.data?.status==="PREMIUM" && getUserInfo?.data?.is_monthly===true) ? `premium-info-active`:""}`} >{get(second, "[0].currency")} {get(second, "[0].price")}</p>
                      <p className= {`premium-subtext ${(getUserInfo?.data?.status==="PREMIUM" && getUserInfo?.data?.is_monthly===true) ? `premium-info-active`:""}`}>/ Oylik</p>
                    </span>
                    <p className={`premium-info ${(getUserInfo?.data?.status==="PREMIUM" && getUserInfo?.data?.is_monthly===true) ? `premium-info-active`:""}`}>
                      Bu bizning bepul tarifimiz. O'z ichiga oladi:
                    </p>
                  </div>
                  <ul className="pre-list">
                    <li className="pre-item">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 11.4182L9.68571 17.8L21 7" stroke={` ${(getUserInfo?.data?.status==="PREMIUM" && getUserInfo?.data?.is_monthly===true) ? `white`:"blueviolet"}`} stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
                      <p className={`pre-subinfo ${(getUserInfo?.data?.status==="PREMIUM" && getUserInfo?.data?.is_monthly===true) ? `premium-info-active`:""}`}>
                        {get(second, "[0].description1")}
                      </p>
                    </li>
                    <li className="pre-item">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 11.4182L9.68571 17.8L21 7" stroke={` ${(getUserInfo?.data?.status==="PREMIUM" && getUserInfo?.data?.is_monthly===true) ? `white`:"blueviolet"}`} stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
                      <p className={`pre-subinfo ${(getUserInfo?.data?.status==="PREMIUM" && getUserInfo?.data?.is_monthly===true) ? `premium-info-active`:""}`}>
                        {get(second, "[0].description2")}
                      </p>
                    </li>
                    <li className="pre-item">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 11.4182L9.68571 17.8L21 7" stroke={` ${(getUserInfo?.data?.status==="PREMIUM" && getUserInfo?.data?.is_monthly===true) ? `white`:"blueviolet"}`} stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
                      <p className={`pre-subinfo ${(getUserInfo?.data?.status==="PREMIUM" && getUserInfo?.data?.is_monthly===true) ? `premium-info-active`:""}`}>
                        {get(second, "[0].description3")}
                      </p>
                    </li>
                  </ul>

                  {(getUserInfo?.data?.status==="PREMIUM" && getUserInfo?.data?.is_monthly===true) ? 
                  <button className="subscribe-choose">Siz Premium tarifdasiz</button>:             
                    <div onClick={() => setCurrency(get(second, "[0].currency"))}>
                   <div onClick={() => setTarifId(get(second, "[0].id"))}>
                     <div onClick={() => setTarifName(get(second, "[0].name"))}>
                       <div onClick={() => setTarifPrice(get(second, "[0].price"))}>
                         <SubscribeModal  />
                       </div>
                     </div>
                   </div>
                 </div>}
                </div>
              </SwiperSlide>
              <SwiperSlide>
              <div className={`premium-section ${(getUserInfo?.data?.status==="BIZNES" && getUserInfo?.data?.is_monthly===true) ? `premium-section-active`:""}`}>
                  <div className="pre-top">
                    <h3 className={`pre-title ${(getUserInfo?.data?.status==="BIZNES" && getUserInfo?.data?.is_monthly===true) ? `modal-status-active`:""}`} >{get(third, "[0].name")}</h3>
                    <span className="premium-span">
                      <p className={`premium-price ${(getUserInfo?.data?.status==="BIZNES" && getUserInfo?.data?.is_monthly===true) ? `premium-info-active`:""}`} >{get(third, "[0].currency")} {get(third, "[0].price")}</p>
                      <p className= {`premium-subtext ${(getUserInfo?.data?.status==="BIZNES" && getUserInfo?.data?.is_monthly===true) ? `premium-info-active`:""}`}>/ Oylik</p>
                    </span>
                    <p className={`premium-info ${(getUserInfo?.data?.status==="BIZNES" && getUserInfo?.data?.is_monthly===true) ? `premium-info-active`:""}`}>
                      Bu bizning bepul tarifimiz. O'z ichiga oladi:
                    </p>
                  </div>
                  <ul className="pre-list">
                    <li className="pre-item">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 11.4182L9.68571 17.8L21 7" stroke={` ${(getUserInfo?.data?.status==="BIZNES" && getUserInfo?.data?.is_monthly===true) ? `white`:"blueviolet"}`} stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
                      <p className={`pre-subinfo ${(getUserInfo?.data?.status==="BIZNES" && getUserInfo?.data?.is_monthly===true) ? `premium-info-active`:""}`}>
                        {get(third, "[0].description1")}
                      </p>
                    </li>
                    <li className="pre-item">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 11.4182L9.68571 17.8L21 7" stroke={` ${(getUserInfo?.data?.status==="BIZNES" && getUserInfo?.data?.is_monthly===true) ? `white`:"blueviolet"}`} stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
                      <p className={`pre-subinfo ${(getUserInfo?.data?.status==="BIZNES" && getUserInfo?.data?.is_monthly===true) ? `premium-info-active`:""}`}>
                        {get(third, "[0].description2")}
                      </p>
                    </li>
                    <li className="pre-item">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 11.4182L9.68571 17.8L21 7" stroke={` ${(getUserInfo?.data?.status==="BIZNES" && getUserInfo?.data?.is_monthly===true) ? `white`:"blueviolet"}`} stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
                      <p className={`pre-subinfo ${(getUserInfo?.data?.status==="BIZNES" && getUserInfo?.data?.is_monthly===true) ? `premium-info-active`:""}`}>
                        {get(third, "[0].description3")}
                      </p>
                    </li>
                  </ul>
                  {(getUserInfo?.data?.status==="BIZNES" && getUserInfo?.data?.is_monthly===true) ? 
                  <button className="subscribe-choose">Siz Biznes tarifdasiz</button>:             
                    <div onClick={() => setCurrency(get(third, "[0].currency"))}>
                   <div onClick={() => setTarifId(get(third, "[0].id"))}>
                     <div onClick={() => setTarifName(get(third, "[0].name"))}>
                       <div onClick={() => setTarifPrice(get(third, "[0].price"))}>
                         <SubscribeModal  />
                       </div>
                     </div>
                   </div>
                 </div>}


                </div>
              </SwiperSlide>
            </Swiper>
          </ModalTabPanel>
          <ModalTabPanel value={value} index={1} dir={theme.direction}>
            <Swiper
              slidesPerView={3}
              spaceBetween={30}
              slidesPerGroup={1}
              loop={true}
              loopFillGroupWithBlank={true}
              navigation={true}
              modules={[Navigation]}
              className="premium-swiper mySwiper">
              <SwiperSlide>
              <div className={`premium-section ${(getUserInfo?.data?.status==="FREE"  && getUserInfo?.data?.is_monthly===true) ? `premium-section-active`:""}`}>
                  <div className="pre-top">
                    <h3 className={`pre-title ${(getUserInfo?.data?.status==="FREE"  && getUserInfo?.data?.is_monthly===true) ? `modal-status-active`:""}`} >{get(first, "[0].name")}</h3>
                    <span className="premium-span">
                      <p className={`premium-price ${(getUserInfo?.data?.status==="FREE"  && getUserInfo?.data?.is_monthly===true) ? `premium-info-active`:""}`} >{get(first, "[0].currency")} {get(first, "[0].price")}</p>
                      <p className= {`premium-subtext ${(getUserInfo?.data?.status==="FREE"  && getUserInfo?.data?.is_monthly===true) ? `premium-info-active`:""}`}>/ Yillik</p>
                    </span>
                    <p className={`premium-info ${(getUserInfo?.data?.status==="FREE"  && getUserInfo?.data?.is_monthly===true) ? `premium-info-active`:""}`}>
                      Bu bizning bepul tarifimiz. O'z ichiga oladi:
                    </p>
                  </div>
                  <ul className="pre-list">
                    <li className="pre-item">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 11.4182L9.68571 17.8L21 7" stroke={` ${(getUserInfo?.data?.status==="FREE" && getUserInfo?.data?.is_monthly===true) ? `white`:"blueviolet"}`} stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
                      <p className={`pre-subinfo ${(getUserInfo?.data?.status==="FREE"  && getUserInfo?.data?.is_monthly===true) ? `premium-info-active`:""}`}>
                        {get(first, "[0].description1")}
                      </p>
                    </li>
                    <li className="pre-item">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 11.4182L9.68571 17.8L21 7" stroke={` ${(getUserInfo?.data?.status==="FREE" && getUserInfo?.data?.is_monthly===true) ? `white`:"blueviolet"}`} stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
                      <p className={`pre-subinfo ${(getUserInfo?.data?.status==="FREE"  && getUserInfo?.data?.is_monthly===true) ? `premium-info-active`:""}`}>
                        {get(first, "[0].description2")}
                      </p>
                    </li>
                    <li className="pre-item">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 11.4182L9.68571 17.8L21 7" stroke={` ${(getUserInfo?.data?.status==="FREE" && getUserInfo?.data?.is_monthly===true) ? `white`:"blueviolet"}`} stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
                      <p className={`pre-subinfo ${(getUserInfo?.data?.status==="FREE"  && getUserInfo?.data?.is_monthly===true) ? `premium-info-active`:""}`}>
                        {get(first, "[0].description3")}
                      </p>
                    </li>
                  </ul>
                  {(getUserInfo?.data?.status==="FREE" && getUserInfo?.data?.is_monthly===true) ? 
                  <button className="subscribe-choose">Siz FREE tarifdasiz</button>:             
                    <div onClick={() => setCurrency(get(first, "[0].currency"))}>
                   <div onClick={() => setTarifId(get(first, "[0].id"))}>
                     <div onClick={() => setTarifName(get(first, "[0].name"))}>
                       <div onClick={() => setTarifPrice(get(first, "[0].price"))}>
                         <SubscribeModal  />
                       </div>
                     </div>
                   </div>
                 </div>}
                </div>
              </SwiperSlide>
              <SwiperSlide>
              <div className={`premium-section ${(getUserInfo?.data?.status==="PREMIUM" && getUserInfo?.data?.is_yearly===true) ? `premium-section-active`:""}`}>
                  <div className="pre-top">
                    <h3 className={`pre-title ${(getUserInfo?.data?.status==="PREMIUM" && getUserInfo?.data?.is_yearly===true) ? `modal-status-active`:""}`}>{get(secondYear, "[0].name")}</h3>
                    <span className="premium-span">
                    <p className={`premium-price ${(getUserInfo?.data?.status==="PREMIUM" && getUserInfo?.data?.is_yearly===true) ? `premium-info-active`:""}`} >{get(secondYear, "[0].currency")} {get(secondYear, "[0].price")}</p>
                      <p className= {`premium-subtext ${(getUserInfo?.data?.status==="PREMIUM" && getUserInfo?.data?.is_yearly===true) ? `premium-info-active`:""}`}>/ Yillik</p>
                    </span>
                    <p className={`premium-info ${(getUserInfo?.data?.status==="PREMIUM" && getUserInfo?.data?.is_yearly===true) ? `premium-info-active`:""}`}>
                      Bu bizning bepul tarifimiz. O'z ichiga oladi:
                    </p>
                  </div>
                  <ul className="pre-list">
                    <li className="pre-item">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 11.4182L9.68571 17.8L21 7" stroke={` ${(getUserInfo?.data?.status==="PREMIUM" && getUserInfo?.data?.is_yearly===true) ? `white`:"blueviolet"}`} stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
                      <p className={`pre-subinfo ${(getUserInfo?.data?.status==="PREMIUM" && getUserInfo?.data?.is_yearly===true) ? `premium-info-active`:""}`}>
                        {get(secondYear, "[0].description1")}
                      </p>
                    </li>
                    <li className="pre-item">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 11.4182L9.68571 17.8L21 7" stroke={` ${(getUserInfo?.data?.status==="PREMIUM" && getUserInfo?.data?.is_yearly===true) ? `white`:"blueviolet"}`} stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
                      <p className={`pre-subinfo ${(getUserInfo?.data?.status==="PREMIUM" && getUserInfo?.data?.is_yearly===true) ? `premium-info-active`:""}`}>
                        {get(secondYear, "[0].description2")}
                      </p>
                    </li>
                    <li className="pre-item">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 11.4182L9.68571 17.8L21 7" stroke={` ${(getUserInfo?.data?.status==="PREMIUM" && getUserInfo?.data?.is_yearly===true) ? `white`:"blueviolet"}`} stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
                      <p className={`pre-subinfo ${(getUserInfo?.data?.status==="PREMIUM" && getUserInfo?.data?.is_yearly===true) ? `premium-info-active`:""}`}>
                        {get(secondYear, "[0].description3")}
                      </p>
                    </li>
                  </ul>
                  {(getUserInfo?.data?.status==="PREMIUM" && getUserInfo?.data?.is_yearly===true) ? 
                  <button className="subscribe-choose">Siz Premium tarifdasiz</button>:             
                    <div onClick={() => setCurrency(get(secondYear, "[0].currency"))}>
                   <div onClick={() => setTarifId(get(secondYear, "[0].id"))}>
                     <div onClick={() => setTarifName(get(secondYear, "[0].name"))}>
                       <div onClick={() => setTarifPrice(get(secondYear, "[0].price"))}>
                         <SubscribeModal  />
                       </div>
                     </div>
                   </div>
                 </div>}
                </div>
              </SwiperSlide>
              <SwiperSlide>
              <div className={`premium-section ${(getUserInfo?.data?.status==="BIZNES" && getUserInfo?.data?.is_yearly===true) ? `premium-section-active`:""}`}>
                  <div className="pre-top">
                    <h3 className={`pre-title ${(getUserInfo?.data?.status==="BIZNES" && getUserInfo?.data?.is_yearly===true) ? `modal-status-active`:""}`} >{get(thirdYear, "[0].name")}</h3>
                    <span className="premium-span">
                      <p className={`premium-price ${(getUserInfo?.data?.status==="BIZNES" && getUserInfo?.data?.is_yearly===true) ? `premium-info-active`:""}`} >{get(thirdYear, "[0].currency")} {get(thirdYear, "[0].price")}</p>
                      <p className= {`premium-subtext ${(getUserInfo?.data?.status==="BIZNES" && getUserInfo?.data?.is_yearly===true) ? `premium-info-active`:""}`}>/ Yillik</p>
                    </span>
                    <p className={`premium-info ${(getUserInfo?.data?.status==="BIZNES" && getUserInfo?.data?.is_yearly===true) ? `premium-info-active`:""}`}>
                      Bu bizning bepul tarifimiz. O'z ichiga oladi:
                    </p>
                  </div>
                  <ul className="pre-list">
                    <li className="pre-item">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 11.4182L9.68571 17.8L21 7" stroke={` ${(getUserInfo?.data?.status==="BIZNES" && getUserInfo?.data?.is_yearly===true) ? `white`:"blueviolet"}`} stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
                      <p className={`pre-subinfo ${(getUserInfo?.data?.status==="BIZNES" && getUserInfo?.data?.is_yearly===true) ? `premium-info-active`:""}`}>
                        {get(thirdYear, "[0].description1")}
                      </p>
                    </li>
                    <li className="pre-item">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 11.4182L9.68571 17.8L21 7" stroke={` ${(getUserInfo?.data?.status==="BIZNES" && getUserInfo?.data?.is_yearly===true) ? `white`:"blueviolet"}`} stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
                      <p className={`pre-subinfo ${(getUserInfo?.data?.status==="BIZNES" && getUserInfo?.data?.is_yearly===true) ? `premium-info-active`:""}`}>
                        {get(thirdYear, "[0].description2")}
                      </p>
                    </li>
                    <li className="pre-item">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 11.4182L9.68571 17.8L21 7" stroke={` ${(getUserInfo?.data?.status==="BIZNES" && getUserInfo?.data?.is_yearly===true) ? `white`:"blueviolet"}`} stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
                      <p className={`pre-subinfo ${(getUserInfo?.data?.status==="BIZNES" && getUserInfo?.data?.is_yearly===true) ? `premium-info-active`:""}`}>
                        {get(thirdYear, "[0].description3")}
                      </p>
                    </li>
                  </ul>
                  {(getUserInfo?.data?.status==="BIZNES" && getUserInfo?.data?.is_monthly===true) ? 
                  <button className="subscribe-choose">Siz Biznes tarifdasiz</button>:             
                    <div onClick={() => setCurrency(get(thirdYear, "[0].currency"))}>
                   <div onClick={() => setTarifId(get(thirdYear, "[0].id"))}>
                     <div onClick={() => setTarifName(get(thirdYear, "[0].name"))}>
                       <div onClick={() => setTarifPrice(get(thirdYear, "[0].price"))}>
                         <SubscribeModal  />
                       </div>
                     </div>
                   </div>
                 </div>}
                </div>
              </SwiperSlide>
            </Swiper>
          </ModalTabPanel>
        </SwipeableViews>
      </Box>
    </div>
  );
};

export default ModalProPage;
