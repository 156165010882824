import { useState } from "react";
import { createContext } from "react";

const SearchImgContext = createContext();

function SearchImgFunction({children}){
    const [searchImg,setSearchImg] = useState("");

    return(
        <SearchImgContext.Provider value={[searchImg,setSearchImg]} >
            {children}
        </SearchImgContext.Provider>
    )
}

export {SearchImgContext, SearchImgFunction};