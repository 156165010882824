import ReactLoading from "react-loading";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useSchedulePut } from "../../../services/mutations/edit-put-schedule";
import { GetScheduleLinkLists } from "../../../services/query/get-schedule";
import { get } from "lodash";
import { GetEditLinkLists } from "../../../services/query/get-edit-link-list";
import { useParams } from "react-router-dom";
import { useLinkDelete } from "../../../services/mutations/delete-link";
import { toast } from "react-toastify";

export default function ScheduleEdit({ linkId }) {
 const { data} = GetScheduleLinkLists(linkId);
  const {
    handleSubmit,
    register,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues:{
      schedule_start: data?.schedule_start,
      schedule_end: data?.schedule_end,
     }
  });
   const linkEditList = GetScheduleLinkLists(linkId);
  const { mutate, isLoading } = useSchedulePut(linkId);

  const changeScheduleUpdate = async (values) => {
    const requestData = {
      schedule_start: values?.schedule_start,
      schedule_end: values?.schedule_end,
      };
     mutate(requestData, {
      onSuccess: () => {
        toast.success("Kalendar muvaffaqiyatli tahrirlandi!", {
          position: "top-right",
        });
        setIsChanged(!isChanged);
        window.location.reload();
      },
    });
  };
   ///****************************  link delete  ********************** */
   const [isChanged, setIsChanged] = useState(false);
   const deleteLink = useLinkDelete(linkId);
   const changeLinkDelete = async () => {
     deleteLink?.mutate("", {
       onSuccess: () => {
         toast.success("Link muvaffaqiyatli o'chirildi!", {
           position: "top-right",
         });
         setIsChanged(!isChanged);
         window.location.reload();
       },
     });
   };

   return (
    <form className="day-premium">
      {/* <div className="schedule-top">
        <p className="schedule-text">Yoqilgan</p>
        <div>
          <div >
          <input className="schedule-toggle"       
          // defaultValue={get(linkEditList,"data.enabled")}
                      {...register("enabled")} 
                      type="checkbox" id={get(linkEditList,"data.guid")}  />
                      <label  className="schedule-label" htmlFor={get(linkEditList,"data.guid")}>Toggle</label>
          </div>
        </div>
      </div> */}
      <div className="days-schedule">
        <div className="day-start">
          <label> Boshlanish vaqti</label>
          <input
            type="date"
            id="meeting-time"
            name="meeting-time"
  
             {...register("schedule_start")}
          />
        </div>
        <div className="day-start">
          <label> Tugash vaqti</label>
          <input
            type="date"
            id="meeting-time"
            name="meeting-time"
            {...register("schedule_end")}
          />
        </div>
      </div>
      {data?.enabled === true ? <p style={{color:"red", padding:"10px 0"}}>Sizda belgilangan muddat tugagan, yangi muddat belgilang</p> : ""}

      <div className="add-footer">
        <button className="add-cancel" type="button"  onClick={handleSubmit(changeLinkDelete)}>
          O'chirish
        </button>
        <button className="add-create" type="submit" onClick={handleSubmit(changeScheduleUpdate)}>
          {isLoading ? (
            <ReactLoading type="bubbles" color="#fff" height={40} width={40} />
          ) : (
            "Yangilash"
          )}
        </button>
      </div>
    </form>
  );
}
