import "./ResultPage.css";
import resultpageImg from "../../Assets/Img/avatar1.png";
import defaultImg from "../../Assets/default.svg";
import MapsIcon from "./../../Assets/Img/MapsIcon";
import { Link, useLocation, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { Context } from "../../Context/ThemeContext";
import resultpageLogo from "../../Assets/Img/LogoImg.png";
import "../../Components/AdminProfil/AdminProfil.css";
import styled from "styled-components";
import { MatnContext } from "../../Context/TextContext";
import { ButtonTextContext } from "../../Context/ButtonTextContext";
import { ColorContext } from "../../Context/ColorContext";
import { Animated } from "react-animated-css";
import { AnimationContext } from "../../Context/AnimationContext";
import { FontContext } from "../../Context/FontContext";
import { SocialIcon } from "react-social-icons";
import { GetBackgroundColor } from "../../services/query/get-background-color";
import { GetLinkLists } from "../../services/query/get-link-lists";
import { GetProfilesDetails } from "../../services/query/get-profile-details";
import { get } from "lodash";
import ReactLoading from "react-loading";
import { Box } from "@mui/material";
import { MainLayout } from "../../Layout/layout-index";
import { GetCustom } from "../../services/query/get-custom";
import { GetCustomDetail } from "../../services/query/get-custom-detail";
import logo from "../../../src/Assets/Img/lg.svg"
const ResultPage = (props) => {
  const [text, setText] = useContext(MatnContext);
  const [buttonTextColor, setButtonTextColor] = useContext(ButtonTextContext);
  const [color1, setColor1] = useContext(ColorContext);
  const { pathname } = useLocation();
  const [animation, setAnimation] = useContext(AnimationContext);
  const [font, setFont] = useContext(FontContext);
  const { username } = useParams();
  const [linkId, setLinkId] = useState("");
  const { data, isSuccess, isFetching } = GetProfilesDetails(username);
  const profileLinks = GetLinkLists(username);
  const themeColors = GetBackgroundColor(username);
  const profile = GetProfilesDetails(username);
  let id = get(profile, "data[0].id");
  const getCustomId = GetCustomDetail(id);
   const cusId = get(getCustomId, "data[0].id")
  const custom = GetCustom(cusId);
     return (
    <>
      {!themeColors?.isFetching &&
        !isFetching &&
        isSuccess &&
        get(data, "length") > 0 && (
          <MainLayout {...{ custom }}> 
          <section
            className={`resultpage ${  pathname === `/${username}` 
            ?
             ((themeColors?.data?.background_color=== "classic" ? 'main-style' : themeColors?.data?.background_color == ""  ? 'main-style' 
             : "") ) : ""   }
             adminProfile-page-${themeColors?.data?.background_color}`}
          >
            <div className="container">
              <div className="resultpage-main">
                <div className="resultpage-wrapper">
                  <div className="resultpage-top">
                    <div className="result-image-wrapper">
                      <img
                        src={
                          `https://api-toplink.uz${get(
                            data,
                            "[0].profile_img"
                          )}` || defaultImg
                        }
                        alt=""
                        className="resultpage-avatar"
                      />
                    </div>

                    <MainBlok {...{ custom }} className="main-blok-result">
                      <h2
                        className={`main-name main-name-${font} main-name-${themeColors?.data?.background_color}`}
                      >
                        {get(data, "[0].display_name") ||
                          "@" + get(data, "[0].username")}
                      </h2>
                      {get(data, "[0].location") && (
                        <div
                          className={`main-map main-map-${themeColors?.data?.background_color}`}
                        >
                          <MapsIcon />
                          <p
                            className={`main-text main-text-${font} 
                          main-text-${themeColors?.data?.background_color}`}
                          >
                            {get(data, "[0].location")}
                          </p>
                        </div>
                      )}
                      <p
                        className={`main-subname main-subname-${font} main-subname-${themeColors?.data?.background_color}`}
                      >
                        {get(data, "[0].bio")}
                      </p>
                    </MainBlok>
                  </div>
                  <ResultPageList {...{ custom }} {...{ buttonTextColor }}>
                    {profileLinks?.data?.map((event, i) => (
                      <li
                        key={i}
                        className={`resultpage-item
         adminSocial-item-${themeColors?.data?.background_color}`}
                      >
                        <Animated
                          // animationIn="lightSpeedIn"
                          animationOut={event?.animation}
                          animationInDuration={1000}
                          animationOutDuration={1000}
                          isVisible={true}
                          className={event?.animation}

                        >
                          <a
                            href={`${event.icon_url}`}
                            onClick={() => setLinkId(event.guid)}
                            target="_blank"
                            className={`adminSocial-link adminSocial-link-${custom?.data?.button_shape} resultpage-link 
                    adminSocial-link-${themeColors?.data?.background_color}`}
                            rel="noreferrer"
                          >
                            <div
                              className={`adminSocial-boxes
                    adminSocial-boxes-${themeColors?.data?.background_color}`}
                            >
                              <div
                                className={`adminSocial-box1 
                            adminSocial-box1-${themeColors?.data?.background_color}`}
                              >
                                  {event?.thumbnail===null ? <SocialIcon target="_blank" datasetId={event?.guid} style={{ fontSize: "10px" }} url={event?.icon_url}  />
:                        <img src={`https://api-toplink.uz${event?.thumbnail}`} alt={event?.thumbnail}  style={{width:"32px",height:"32px", borderRadius:"50%"}}/>}
           
                              </div>
                            </div>
                            <div
                              className={`adminSocial-name adminSocial-name-${font} adminSocial-name-${themeColors?.data?.background_color}`}
                            >
                              {event.icon_name}
                            </div>
                          </a>
                        </Animated>
                      </li>
                    ))}
                  </ResultPageList>
                </div>

                <div className="resultpage-footer">
                  <a href="https://toplink.uz/" className="resultpage-link">
                    <img
                      src={logo}
                      alt=""
                      className="resultpage-logo"
                    />
                  </a>
                </div>
              </div>
            </div>
          </section>
          </MainLayout>
        )}
      {themeColors?.isFetching && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
            backgroundColor: "#6036b2",
          }}
        >
          <ReactLoading
            type="spinningBubbles"
            color="#fff"
            height={100}
            width={100}
          />
        </Box>
      )}
      {!isFetching && get(data, "length") < 1 && <>This user is not found!</>}
    </>
  );
};

export default ResultPage;

export const MainBlok = styled.div`
margin-left:50px;
width: 100%;
 @media (max-width: 768px) {
   margin-left:0;
  }
   .main-name{
        font-style: normal;
        font-weight: 500;
        font-size: 32px;
        margin-top:20px;

        width: 100%;
        color: ${(props) => (props.text && props.text.length > 0 ? props.text : "#01384D")}; 
        
    }
    div{
        display: flex;
        border: 1px solid #512DA8;
        border-radius: 30px;
        padding: 8px 5px;
        padding-left: 15px;
        width: 200px;
        margin: 8px 0;
    }
    .main-text{
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        margin-left: 10px;
        height: 25px;
        color: ${(props) => ( props.text &&
          props.text.length > 0 ? props.text : "#01384D")} 
   
    }
    .main-subname{
        font-style: normal;
        font-weight: 400;
        font-size: 17px;
        color: ${(props) => (props.text && props.text.length > 0 ? props.text : "#01384D")} ;
        width: 100%;
    }
    .main-map{
        display: flex;
       border: 1px solid ${(props) => props.text &&
         props.text.length > 0 ? props.text : "#512DA8"};
       border-radius: 30px;
       padding: 8px 5px;
       padding-left: 15px;
       width: 100%;
       margin: 8px 0; 
   }
   .main-map-blue, .main-map-dark,.main-map-dusk,.main-map-purple , .main-map-warm  {
    border: 1px solid white;
}
.main-subname-blue, .main-subname-dark,.main-subname-dusk,.main-subname-purple , .main-subname-warm,.main-subname-neon, .main-subname-pastel, .main-subname-luxury,.main-subname-stitched,.main-subname-sunset,.main-subname-friendly {
    color: white;
}
.main-map-neon {
    box-shadow: 0.5px 0.5px 4px #EA33F7;
    border: 2px solid #EA33F7;
}
.main-map-pastel {
    border: 2px solid white;
}
.main-map-luxury {
    background: linear-gradient(90.12deg, #C7AD5C 0.6%, #D8C78D 58.18%, #877335 99.9%);
    border: none;
}
.main-map-stitched,.main-map-sunset, .main-map-friendly  {
    background: transparent;
    border: 1px solid white;
}

 .main-name-blue,.main-name-dark,.main-name-dusk,.main-name-purple
 ,.main-name-warm, .main-name-neon, .main-name-pastel, .main-name-luxury  
 ,.main-name-stitched, .main-name-sunset, .main-name-friendly  {
    color: #FFFFFF;
}

 .main-text-blue, .main-text-dark,  .main-text-dusk,
   .main-text-purple, .main-text-warm, .main-text-pastel,  .main-text-stitched, .main-text-sunset, .main-text-friendly {
    color: white;
}

.main-text-neon {
    color:#FFFF54;
}

 .main-text-luxury,
 .main-text-luxury {
    color: black;
}
 
.main-icon {
    fill: ${(props) => ( props.text && props.text.length > 0 ? props.text : "black")} ;
    width: 15px;
    height: 18px;
    margin-top: 4px;

}
.main-icon-blue,.main-icon-dark,.main-icon-dusk, .main-icon-purple, .main-icon-warm, .main-icon-pastel,, .main-icon-sunset,.main-icon-friendly ,.main-icon-stitched {
    fill: white;
}

.main-icon-neon {
    fill: #FFFF54;
}
    
.main-icon-luxury{
    fill:black;
}


@media(max-width:720px){
    .container{
        width:100%;
        padding:0 15px;
    }
    .main-map{
    width: 100%;

    }
}
@media(max-width:420px){
  .container {
    width: 100%;
    padding: 0px 15px;
  }
    .main-name{
        font-size: 26px;
         margin-top:15px;
         font-weight:600;
    }
    .main-map {
        width: 100%;
        margin: 10px auto;
}
`;

export const ResultPageList = styled.ul`
  padding: 30px 0;
  width: 100%;
  .adminSocial-link {
    display: flex;
    align-items: center;
    width: 100%;
    height: 54px;
    padding: 0px 16px;
    background: ${(props) =>
      (props.custom?.data?.button_color && props.custom?.data?.button_color.length > 0 ?
          props?.custom?.data?.button_color : "#84b0ec")};
    border-radius: 12px;
  }
  .adminSocial-link-border1{
    border-radius: 20px;
  }
  .adminSocial-link-border2{
    border-radius: 20px;
    background: transparent;
    border:1px solid ${(props) =>
      (props.custom?.data?.button_color && props.custom?.data?.button_color.length > 0 ?
          props?.custom?.data?.button_color : "#84b0ec")};
  }
  .adminSocial-link-border3{
    border-radius: 8px;
    background: ${(props) =>
      (props.custom?.data?.button_color && props.custom?.data?.button_color.length > 0 ?
          props?.custom?.data?.button_color : "#84b0ec")};;
     opacity: 0.3;
  }
  .adminSocial-link-border4{
    border-radius: 8px;
    background: transparent;
    border:1px solid ${(props) =>
      (props.custom?.data?.button_color && props.custom?.data?.button_color.length > 0 ?
          props?.custom?.data?.button_color : "#84b0ec")};
  }
  .adminSocial-link-border5{
    border-radius: 8px;
    background: ${(props) =>
      (props.custom?.data?.button_color && props.custom?.data?.button_color.length > 0 ?
          props?.custom?.data?.button_color : "#84b0ec")};;
  }
  .adminSocial-link-border6{
    border-radius: 8px;
    background: transparent;
    border:1px solid ${(props) =>
      (props.custom?.data?.button_color && props.custom?.data?.button_color.length > 0 ?
          props?.custom?.data?.button_color : "#84b0ec")};
  }
  .adminSocial-name {
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 54px;
    border-left: 0.1px solid rgb(229, 229, 229);
    padding-left: 16px;
    color: ${(props) =>
      (props.custom?.data?.button_text_color && props.custom?.data?.button_text_color.length> 0 ?  props?.custom?.data?.button_text_color : "white")};
    white-space: nowrap;
    text-overflow: hidden;
    height: 54px;
  }
  .adminSocial-item {
    margin: 15px 0;
  }
  .adminSocial-boxes {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    margin: 0px 15px;
    margin-left: 0;
    margin-right: 16px;
    align-items: center;

    border-radius: 8px;
  }

  .adminSocial-link-blue {
    background: rgba(255, 255, 255, 0.15);
    box-shadow: 0.5px 0.5px 4px rgba(0, 0, 0, 0.15);
  }
  .adminSocial-link-dark {
    background: #2253ef;
    box-shadow: 0.5px 0.5px 4px rgba(0, 0, 0, 0.15);
  }

  .adminSocial-link-dusk,
  .adminSocial-link-pastel,
  .adminSocial-link-sunset {
    background: rgba(0, 0, 0, 0.15);
    box-shadow: 0.5px 0.5px 4px rgba(0, 0, 0, 0.15);
  }
  .adminSocial-link-purple {
    background: transparent;
    box-shadow: 0.5px 0.5px 4px rgba(0, 0, 0, 0.15);
    border: 1px solid #ffffff;
  }
  .adminSocial-link-warm {
    background: transparent;
    box-shadow: 0.5px 0.5px 4px rgba(0, 0, 0, 0.15);
    border: 1px solid transparent;
  }
  .adminSocial-link-neon {
    background: transparent;
    box-shadow: 0.5px 0.5px 4px #ffff54;
    border: 2px solid #ffff00;
  }
  .adminSocial-link-luxury {
    background: transparent;
    border: 2px solid #c7ad5c;
  }
  .adminSocial-link-stitched {
    background: transparent;
    border: 2px dashed rgba(0, 0, 0, 0.2);
  }
  .adminSocial-link-friendly {
    background: white;
    color: black;
    box-shadow: 0.5px 0.5px 4px rgba(0, 0, 0, 0.15);
  }

  .adminSocial-name-blue,
  .adminSocial-name-dark,
  .adminSocial-name-dusk,
  .adminSocial-name-purple,
  .adminSocial-name-warm,
  .adminSocial-name-neon,
  .adminSocial-name-pastel,
  .adminSocial-name-luxury {
    color: white;
  }
  .adminSocial-name-stitched {
    border-left: none;
    color: white;
  }

  .adminSocial-name-sunset {
    color: white;
    border-left: 0.5px solid #abbac3;
  }
  .adminSocial-name-friendly {
    color: black;
    border-left: 0.5px solid #abbac3;
  }

  .adminSocial-item-stitched {
    padding: 2.5px;
    border-radius: 12px;
    background: #2253ef;
  }

  .adminSocial-boxes-stitched,
  .adminSocial-boxes-sunset,
  .adminSocial-boxes-friendly {
    background-color: transparent;
  }
  .fa-brands {
   font-size: 26px;
    font-weight: 100;
    width: 26px;
    height: 26px;
  }

  .map-svg-blue,
  .map-svg-dark,
  .map-svg-dusk,
  .map-svg-purple,
  .map-svg-warm,
  .map-svg-pastel,
  .map-svg-stitched,
  .map-svg-sunset {
    color: white;
  }
  .map-svg-neon {
    color: yellow;
  }
  .map-svg-luxury {
    color: #c7ad5c;
  }
  .map-svg-friendly {
    color: black;
  }
  @media (max-width: 920px) {
    .container {
      width: 100%;
    }
    .adminSocial-link {
      width: 100%;
    }
  }
  @media (max-width: 420px) {
    .container {
      width: 100%;
      padding: 0px 15px;
    }
    .adminSocial-link {
      width: 100%;
    }
  }

  .social-icon {
    width: 38px !important;
    height: 38px !important;
    background: transparent;
    color: white;
    fill: white;
  }
  .social-svg {
    width: 76% !important;
    height: 76% !important;
    top: 4px !important;
    left: 4px !important;
    fill: whiteimport { MainLayout } from './../../Layout/layout-index';
;
    background: white;
  }
`;
