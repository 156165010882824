import {  useParams } from "react-router-dom";
import "./Edit.css";
import { SocialIcon } from "react-social-icons";
import dragIcon from "../../Assets/Img/drag_indicator.png";
import { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FloatingActionButtonZoom from "./TabPanel";
import { LinkContext } from "../../Context/LinkContext";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import * as React from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { get } from "lodash";
import ProfileEdit from "./EditProfile";
import { ToastContainer } from "react-toastify";
import { GetProfilesDetails } from "../../services/query/get-profile-details";
import { useUpdateLink } from "../../services/mutations/change-link";
import { useFieldArray, useForm } from "react-hook-form";
import { useLinkDelete } from "../../services/mutations/delete-link";
import ReactLoading from "react-loading";
import { GetEditLinkLists } from "../../services/query/get-edit-link-list";
import EditAnimation from "./components/editAnimation";
import ScheduleEdit from "./components/schedule-edit";
import { GetUserInfo } from "../../services/query/get-user-info";
import EditThubmnail from "./components/editThubmnail";
import EditRedirect from "./components/redirect";
import EditGeneral from "./components/editGeneral";
import LinkProModal from "../LinkProModal/LinkProModal";

/***********************  mui tabpanel ************************ */
function ModalTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`action-tabpanel-${index}`}
      aria-labelledby={`action-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </Typography>
  );
}
ModalTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`action-tabpanel-${index}`}
      aria-labelledby={`action-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </Typography>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `action-tab-${index}`,
    "aria-controls": `action-tabpanel-${index}`,
  };
}
/*************************function code ***************/

const Edit = () => {
  const { username } = useParams();
  const [expanded, setExpanded] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const { data, isFetching } = GetEditLinkLists(username, isChanged);
  const [linkId, setLinkId] = useState("");
   const [inputId, setInputId] = useState(0);
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const profile = GetProfilesDetails(username);
  const getUserInfo = GetUserInfo(localStorage.getItem("id"));
  const handleChange1 = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeIndex = (index) => {
    setValue(index);
  };
  const handleChange = (panel, item, index) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    setInputId(index);
  };
  const { control, formState: { errors }, watch } = useForm();
  useFieldArray({
    control,
    name: "link",
  });

 
  // console.log({`https://api-toplink.uz/${data[1]?.thumbnail}`},"dataaa");

  /************************** html code ****************************** */

  return (
    <section className="edit-main">
      <ToastContainer />
      <ProfileEdit {...{ username }} />
      <div className="edit-footer">
        {/* ************************* tabpanel ******************************** */}
        <Accordion
          expanded={expanded === "panel11"}
          className="edit-boxes"
          onChange={handleChange("panel11")}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header" className="edit-add">
            <Typography className="edit-add-box">
              {!LinkContext || !watch("icon_url") ? (
                <span className="edit-plus">+</span>
              ) : (
                <SocialIcon
                  style={{ fontSize: "10px" }}
                  url={`#`}
                  bgColor="#7979ff"
                />
              )}
              <p className="edit-text">Yangi link qo'shish</p>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className="edit-general">
              <FloatingActionButtonZoom
                profileId={get(profile, "data[0].id")}
                {...{ isChanged, setIsChanged, setExpanded }}
              />
            </Typography>
          </AccordionDetails>
        </Accordion>

        {/* ******************** edit link list get ***************************************** */}
        {!isFetching ? (
          get(data, "length") > 0 &&
          data?.map((item, i) => (
            // console.log(item?.thumbnail,"item?.thumbnailitem?.thumbnail")
            <Accordion
              key={item?.guid}
              onClick={() => setLinkId(item?.guid)}
              expanded={expanded === `panell${item?.guid}`}
              className="edit-boxes"
              onChange={handleChange(`panell${item?.guid}`, item?.guid, i)}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header">
                <>
                  <Typography>
                    <li key={i} className="social-item">
                      <span className="social-link">
                        {item?.thumbnail===null ? <SocialIcon target="_blank" datasetId={item.guid} style={{ fontSize: "10px" }} url={item?.icon_url} />
:                        <img src={`https://api-toplink.uz${item?.thumbnail}`} alt={item?.thumbnail}  style={{width:"32px",height:"32px", borderRadius:"50%"}}/>}
                        <p className="social-name">{item.icon_name}</p>
                        <button className="social-btn">
                          <img src={dragIcon} alt="" className="social-img" />
                        </button>
                      </span>
                    </li>
                  </Typography>
                </>
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="edit-general">
                  <Box
                    sx={{
                      bgcolor: "background.paper",
                      position: "relative",
                      minHeight: 200
                    }}>
                    {/* *************************  app rab bar ************************************************************** */}
                    <AppBar position="static" color="default">
                      <Tabs
                        value={value}
                        onChange={handleChange1}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                        aria-label="action tabs example">
                        <Tab label="Umumiy" {...a11yProps(0)} />
                        <Tab label="Rejalashtirish" key={item?.guid} {...a11yProps(1)} />
                        <Tab label="Animatsiya" {...a11yProps(2)} />
                        <Tab label="Logo" {...a11yProps(3)} />
                        {/* <Tab label="Yo'naltirish" {...a11yProps(4)} /> */}
                      </Tabs>
                    </AppBar>
                    <SwipeableViews
                      axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                      index={value}
                      onChangeIndex={handleChangeIndex}>

                      {/* ************************* link name url ************************************************************** */}

                      <TabPanel
                        style={{ overflow: `hidden` }}
                        value={value}
                        index={0}
                        dir={theme.direction}>
                      <EditGeneral  linkId={linkId} />
                      </TabPanel>
                      {/* ************************* link schedule ************************************************************** */}

                      <TabPanel
                        style={{ overflow: `hidden` }}
                        value={value}
                        index={1}
                        dir={theme.direction}>
                        {get(getUserInfo, "data.status") === "FREE" ? (
                            <LinkProModal />
                        ) : (
                          <ScheduleEdit linkId={linkId} />
                        )}
                      </TabPanel>
                      {/* ************************* link animation ************************************************************** */}

                      <TabPanel
                        style={{ overflow: `hidden` }}
                        value={value}
                        index={2}
                        dir={theme.direction}>
                        {get(getUserInfo, "data.status") === "FREE" ? (
                            <LinkProModal />
                        ) : (
                          <EditAnimation  linkId={linkId} />
                          )}
                      </TabPanel>
                      {/* ************************* link thumbnail ************************************************************** */}

                      <TabPanel
                        style={{ overflow: `hidden` }}
                        value={value}
                        index={3}
                        dir={theme.direction}>
                           {get(getUserInfo, "data.status") === "FREE" ? (
                              <LinkProModal />
                        ) : (
                            <EditThubmnail   linkId={linkId} />
                            )}
                      </TabPanel>
                      {/* ************************* link redirect ************************************************************** */}
{/* 
                      <TabPanel
                        style={{ overflow: `hidden` }}
                        value={value}
                        index={4}
                        dir={theme.direction}>
                    {get(getUserInfo, "data.status") === "FREE" ? (
                        <LinkProModal />
                        ) : (
                          <EditRedirect />
                          )}
                      </TabPanel> */}
                    </SwipeableViews>
                  </Box>
                </Typography>
              </AccordionDetails>
            </Accordion>
          ))
        ) : (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              mt: 5,
            }}
          >
            <ReactLoading type="bars" color="#6036b2" height={60} width={60} />
          </Box>
        )}
      </div>
    </section>
  );
};

export default Edit;
