import { get } from "lodash";
import React from "react";
import { useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useUpdateLink } from "../../../services/mutations/change-link";
import { GetProfilesDetails } from "../../../services/query/get-profile-details";
import { toast } from "react-toastify";
import ReactLoading from "react-loading";
import { GetEditLinkLists } from "../../../services/query/get-edit-link-list";
import { useLinkDelete } from "../../../services/mutations/delete-link";

export default function EditGeneral({ linkId }) {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  useFieldArray({
    control,
    name: "link",
  });
  const { username } = useParams();
  const profile = GetProfilesDetails(username);
  const { mutate, isLoading } = useUpdateLink(linkId);
  const [isChanged, setIsChanged] = useState(false);
  const linkEditList = GetEditLinkLists(username);
  const clickId = linkEditList?.data?.filter((item) => item?.guid === linkId);
  /************************** link update  **********************************/

  const changeLinkGetUpdate = async (values) => {
    const requestData = {
      icon_url: get(values, `link[${linkId}].icon_url`),
      icon_name: get(values, `link[${linkId}].icon_name`),
      profile: get(profile, "data[0].id"),
    };
     mutate(requestData, {
      onSuccess: () => {
        toast.success("Link muvaffaqiyatli tahrirlandi!", {
          position: "top-right",
        });
        setIsChanged(!isChanged);
        window.location.reload();
      },
    });
  };
  ///****************************  link delete  ********************** */
  const deleteLink = useLinkDelete(linkId);
  const changeLinkDelete = async () => {
    deleteLink?.mutate("", {
      onSuccess: () => {
        toast.success("Link muvaffaqiyatli o'chirildi!", {
          position: "top-right",
        });
        setIsChanged(!isChanged);
        window.location.reload();
      },
    });
  };
  return (
    <form className="add-form">
      <div className="add-box">
        <label className="add-label">Link nomi</label>
        <input
          type="text"
          defaultValue={get(clickId, "[0]")?.icon_name}
          className="add-input"
          placeholder="Twitter"
          {...register(`link.${linkId}.icon_name`)}
        />
        {errors?.icon_name && (
          <p style={{ color: "red", fontSize: "13px" }}>
            {errors?.icon_name?.message}
          </p>
        )}
      </div>
      <div className="add-box">
        <label className="add-label">URL</label>
        <input
          type="text"
          defaultValue={get(clickId, "[0]")?.icon_url}
          className="add-input"
          placeholder="https//linkedin.com/rustamjon"
          {...register(`link.${linkId}.icon_url`)}
        />
        {errors?.icon_url && (
          <p style={{ color: "red", fontSize: "13px" }}>
            {errors?.icon_url?.message}
          </p>
        )}
      </div>
      <div className="add-footer">
        <button
          className="add-cancel"
          type="button"
          onClick={handleSubmit(changeLinkDelete)}
        >
          O'chirish
        </button>
        <button
          className="add-create"
          type="submit"
          onClick={handleSubmit(changeLinkGetUpdate)}
        >
          {isLoading ? (
            <ReactLoading type="bubbles" color="#fff" height={40} width={40} />
          ) : (
            "Yangilash"
          )}
        </button>
      </div>
    </form>
  );
}
