import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Box from "@mui/material/Box";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useLinkCreate } from "../../services/mutations/create-link";
import ReactLoading from "react-loading";
import {   toast } from "react-toastify";
import { Link, useParams } from "react-router-dom";
import { GetUserInfo } from "../../services/query/get-user-info";
import { get } from "lodash";
import {Animated} from "react-animated-css";
import { CheckBoxWrapper, CheckBox, CheckBoxLabel } from "./tabpanel.style";
import { usePremiumLinkPost } from "../../services/mutations/create-premium-link";
import LazyImage from "react-lazy-blur-image";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { GetProfilesDetails } from "../../services/query/get-profile-details";
 import { useCallback } from "react";
import getCroppedImg from "./utils/get-cropped-img";
import { Skeleton } from "@mui/material";
import CropperModal from "./utils/cropped-modal";
 import linkdefault from "../../Assets/linkdefaultimg.svg";
import LinkProModal from "../LinkProModal/LinkProModal";

export const ASPECT_RATIO = 3 / 3;
export const CROP_WIDTH = 500;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`action-tabpanel-${index}`}
      aria-labelledby={`action-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </Typography>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
function a11yProps(index) {
  return {
    id: `action-tab-${index}`,
    "aria-controls": `action-tabpanel-${index}`,
  };
}



export default function FloatingActionButtonZoom({profileId,isChanged,setIsChanged,setExpanded}) {
  const theme = useTheme();
  const { username } = useParams();
  const getUserInfo = GetUserInfo(localStorage.getItem("id"));

/*************************   Animation    ********************* */
const [values1, setValues] = useState({});
const orgData = [
    { id: 1, name: "disabled" },
    { id: 2, name: "jello" },
    { id: 3, name: "bounce" },
    { id: 4, name: "pulse" },
    { id: 5, name: "shake" },
    { id: 6, name: "vibrate" },
    { id: 7, name: "wobble" },
  ];
  const handleChangeValues = (e) => {
    setValues((prev) => ({ ...prev, [e?.target?.name]: e?.target?.value }));
  };

/************************* umumiy  ********************* */

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeIndex = (index) => {
    setValue(index);
  };
  const validationSchema = yup.object({
    icon_url: yup
      .string("")
      .matches(  /http/, "URL noto'g'ri kiritildi!")
      .required("To'ldirish majburiy"),
    icon_name: yup.string("").required("To'ldirish majburiy"),
  });

/************************* link create free ********************** */

  const { mutate, isLoading } = useLinkCreate();
  const {handleSubmit,register,reset, watch,formState: { errors }} =
   useForm({ resolver: yupResolver(validationSchema),
    defaultValues: {icon_name: "",
    icon_url: "",
    animation: "disabled",
    schedule_start: null,
    schedule_end: null,
    // redirect: false,
    enabled: false

  }});
  const changeLink = async (values) => {
    const requestData = {
      icon_name: watch("icon_name"),
      icon_url: watch("icon_url"),
      profile: profileId
    };
    mutate(requestData, {
      onSuccess: () => {
        toast.success("Havola muvaffaqiyatli yaratildi!", {
          position: "top-right",
        });
        setExpanded(false);
        setIsChanged(!isChanged);
      },
      onError: (e) => {
         toast.error(e?.response?.data?.message, {
          position: "top-right",
        });
      },
    })};
  useEffect(() => {
    reset({
      icon_name: "",
      icon_url: "",
    });
  }, [isChanged, reset]);
 
/*********************  create-premium link ********************************* */
const [isOpen, setIsOpen] = useState(false);
const [uploadedFile, setUploadedFile] = useState("");
const [minZoom, setMinZoom] = useState(1);
const [crop, setCrop] = useState({ x: 0, y: 0 });
const [zoom, setZoom] = useState(1);
const [imgSrc, setImgSrc] = useState("");
const [croppedAreaPixels, setCroppedAreaPixels] = useState();
const {  isFetching } = GetProfilesDetails(username);
 
  /********************************   **************************************** */

const mutatePremium = usePremiumLinkPost().mutate;
const [croppedImgSrc, setCroppedImgSrc] = useState();
 

const changePremiumLink = async (values) => {
    const formData = new FormData();
    const croppedFile = new File([croppedImgSrc], uploadedFile.name, {
      lastModified: new Date(),
      type: uploadedFile.type,
    });
    if (croppedFile) {
      uploadedFile && formData.append("thumbnail", uploadedFile);
      // formData.append("thumbnail	", thumbnailLink);

    formData.append("icon_name", watch("icon_name"));
    formData.append("icon_url", watch("icon_url"));
    formData.append("profile", profileId);
    formData.append("animation", watch("animation") || "disabled");
    formData.append("schedule_start", watch("schedule_start") || "");
    formData.append("schedule_end", watch("schedule_end") || "");
    // formData.append("redirect", watch("redirect") || false);
    // formData.append("enabled", watch("enabled") || false );
    formData.append("enabled", (( watch("schedule_start")?.lenght>0 || watch("schedule_end")?.length>0)? true: false ));
    mutatePremium(formData, {
      onSuccess: () => {
        toast.success("Havola muvaffaqiyatli yaratildi!", {
          position: "top-right",
        });
        setExpanded(false);
        setIsChanged(!isChanged);
      },
      onError: (e) => {
        toast.error(e?.response?.data?.message, {
          position: "top-right",
        });
      },
    })
  };
}

  useEffect(() => {
    reset({
      icon_name: "",
      icon_url: "",
    });
  }, [isChanged, reset]);

  /**************************** image crop********************* */
  const onMediaLoaded = useCallback((mediaSize) => {
    const { width, height } = mediaSize;
    const mediaAspectRadio = width / height;
    if (mediaAspectRadio > ASPECT_RATIO) {
      const result = CROP_WIDTH / ASPECT_RATIO / height;
      setZoom(result);
      setMinZoom(result);
      return;
    }
    const result = CROP_WIDTH / width;
    setZoom(result);
    setMinZoom(result);
  }, []);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    if (!croppedAreaPixels) return;
    try {
      const croppedImage = await getCroppedImg(imgSrc, croppedAreaPixels);
      setCroppedImgSrc(croppedImage);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, imgSrc]);

  const onFileChange = useCallback(async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      setUploadedFile(e.target.files[0]);
      reader.addEventListener("load", () => {
        if (reader.result) {
          setImgSrc(reader.result.toString() || "");
          setIsOpen(true);
        }
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  }, []);
 
/************************* function free and premium *************************************** */
 const handleLink=()=>{
  if(get(getUserInfo, "data.status")==="FREE"){
    changeLink()
  }else{
    changePremiumLink()
  }
}

/*************************** code *********************************** */

  return (
    <Box sx={{bgcolor: "background.paper",position: "relative",minHeight: 200}}>
         <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="action tabs example">
          <Tab  label="Umumiy" {...a11yProps(0)} />
          <Tab  style={(get(getUserInfo, "data.status") === "PREMIUM" && watch("icon_url").length>0 && watch("icon_name").length>0)  ? {}  : (get(getUserInfo, "data.status") === "FREE") ? {} : {pointerEvents: "none",opacity: "0.2"}}             
            label="Rejalashtirish" {...a11yProps(1) } />
          <Tab style={(get(getUserInfo, "data.status") === "PREMIUM" && watch("icon_url").length>0 && watch("icon_name").length>0)  ? {}  : (get(getUserInfo, "data.status") === "FREE") ? {} : {pointerEvents: "none",opacity: "0.2"}}     label="Animatsiya" {...a11yProps(2)} />
          <Tab style={(get(getUserInfo, "data.status") === "PREMIUM" && watch("icon_url").length>0 && watch("icon_name").length>0)  ? {}  : (get(getUserInfo, "data.status") === "FREE") ? {} : {pointerEvents: "none",opacity: "0.2"}}     label="Logo" {...a11yProps(3)} />
          {/* <Tab style={(get(getUserInfo, "data.status") === "PREMIUM" && watch("icon_url").length>0 && watch("icon_name").length>0)  ? {}  : (get(getUserInfo, "data.status") === "FREE") ? {} : {pointerEvents: "none",opacity: "0.2"}}     label="Yo'naltirish" {...a11yProps(4)} /> */}
        </Tabs>
{/*  *************************   form ****************************** */}
         </AppBar>
         <form className="add-form"  onSubmit={handleSubmit(handleLink)}>
          <SwipeableViews axis={theme.direction === "rtl" ? "x-reverse" : "x"} index={value} onChangeIndex={handleChangeIndex}>

{/* *********************************** umumiy  ******************************************** */}
            <TabPanel
              style={{ overflow: `hidden` }}
              value={value}
              index={0}
              dir={theme.direction}>
              <div className="add-box">
                <label className="add-label">Name</label>
                <input
                  type="text"
                  className="add-input"
                  placeholder="Twitter"
                  {...register("icon_name")}
                />
                {errors?.icon_name && (
                  <p style={{ color: "red", fontSize: "13px" }}>
                    {errors?.icon_name?.message}
                  </p>
                )}
              </div>
              <div className="add-box">
                <label className="add-label">URL</label>
                <input
                  type="text"
                  className="add-input"
                  placeholder="https//Linkedn.com/husanboytursunov"
                  {...register("icon_url")}
                />
                {errors?.icon_url && (
                  <p style={{ color: "red", fontSize: "13px" }}>
                    {errors?.icon_url?.message}
                  </p>
                )}
                {get(getUserInfo, "data.status") === "FREE" ? (
                  <div
                    className="add-footer"
                    style={{ justifyContent: "flex-end" }}>
                    <button className="add-create" type="submit">
                      {isLoading ? (
                        <ReactLoading
                          type="bubbles"
                          color="#fff"
                          height={40}
                          width={40}
                        />
                      ) : (
                        "Yaratish"
                      )}
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </TabPanel>

{/* *********************************** calendar  ******************************************** */}

            <TabPanel
              style={{ overflow: `hidden` }}
              value={value}
              index={1}
              dir={theme.direction}>
              {get(getUserInfo, "data.status") === "FREE" ? (
                  <LinkProModal />
              ) : (
                <div className="day-premium">
                     {/* <div className="schedule-top">
                    <p className="schedule-text">Yoqilgan</p>
                    <div>
                      <div className="">
                    <input className="schedule-toggle"       
                      {...register("enabled")} type="checkbox" id="scheduleswitch" />
                      <label  className="schedule-label" for="scheduleswitch">Toggle</label>
                    </div></div> 
                  </div> */}
                  <div className="days-schedule">
                  <div className="day-start">
                    <label> Boshlanish vaqti</label>
                    <input
                      type="date"
                      id="meeting-time"
                      name="meeting-time"
                         {...register("schedule_start")}  />
                  </div>
                  <div className="day-start">
                    <label> Tugash vaqti</label>
                    <input
                      type="date"
                      id="meeting-time"
                      name="meeting-time"
                      {...register("schedule_end")}/>
                  </div>
                </div>
                </div>
              )}
            </TabPanel>

{/* *********************************** animatsiya  ******************************************** */}

            <TabPanel
              style={{ overflow: `hidden` }}
              value={value}
              index={2}
              dir={theme.direction}>
              {get(getUserInfo, "data.status") === "FREE" ? (
                  <LinkProModal />
              ) : (
                <div className="add-form">
                  <div className="add-box">
                    <label htmlFor="" className="add-label">
                      Animatsiya
                    </label>
                    <select
                      required
                      onChange={handleChangeValues}
                      name="animation"
                      className="animation-select"   {...register("animation")}>
                      {orgData.map((row) => (
                        <option
                          required
                          key={row.id} 
                          selected={row.name === values1.animation?.toLowerCase()}>
                          {row.name}
                        </option>
                      ))}
                    </select>
                  </div>
                 
                  <Animated  className="add-form-preview" 
                  animationIn={watch("animation")}  animationOut="fadeOut" isVisible={true}>
                  
                    Ko'rinishi
                
</Animated>
                </div>
              )}
            </TabPanel>

{/* *********************************** thumbnail  ******************************************** */}

            <TabPanel style={{ overflow: `hidden` }} value={value} index={3} dir={theme.direction}>
            {get(getUserInfo, "data.status") === "FREE" ? (
                <LinkProModal />
              ) : (  
            <div className="" style={{    display: "flex",justifyContent: "center", alignItems: "center"}}>
          <div
            className="edit__left"
            style={{
              position: "relative",
              width: "140px",
              height: "140px",
            }}
          >
            <CropperModal
              crop={crop}
              setCrop={setCrop}
              zoom={zoom}
              setZoom={setZoom}
              onCropComplete={onCropComplete}
              open={isOpen}
              onClose={() => setIsOpen(false)}
              imgSrc={imgSrc}
              showCroppedImage={showCroppedImage}
              onMediaLoaded={onMediaLoaded}
              minZoom={minZoom}
            />
            {!isFetching ? (
              <div className="image-uploader link-image-uploader">
                <LazyImage
                  placeholder={
                    // `https://api-toplink.uz${get(data, "[0].profile_img")}` ||
                    linkdefault
                  }
                  uri={
                    `https://api-toplink.uz/${
                      croppedImgSrc  
                    }` || linkdefault
                  }
                  render={(src, style) => (
                    <img
                      src={
                        uploadedFile ? URL.createObjectURL(uploadedFile) : src
                      }
                      className="link-avatar"
                      alt=""
                      htmlFor="myImage"
                    />
                  )}
                />
                <div className="image-upload-hover">
                  <CloudUploadIcon fontSize="large" sx={{ color: "#fff" }} />
                </div>
                <input
                  type="file"
                  name="myImage"
                  accept="image/png, 
      image/jpeg, image/jpg"
                  style={{
                    opacity: 0,
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%,-50%)",
                    width: "100%",
                    height: "100%",
                  }}
                  onChange={onFileChange}
                />
              </div>
            ) : (
              <Skeleton
                sx={{ backgroundColor: "#f7f7f7" }}
                variant="circular"
                width="130px"
                height="130px"
              />
            )}
          </div>
              </div>
              )
            }

             </TabPanel>
{/* *********************************** redirect  ******************************************** */}
          {/* <TabPanel
              style={{ overflow: `hidden` }}
              value={value}
              index={4}
              dir={theme.direction}>
              {get(getUserInfo, "data.status") === "FREE" ? (
                  <LinkProModal />
              ) : (
                <div className="redirect">
                  <p className="redirect-title">
                  Yoqilganda barcha trafikni ushbu havolaga yo'naltiradi.

                  </p>
                  <div className="schedule-top">
                    <p className="schedule-text">Yoqilgan</p>
                    <div>
                      <CheckBoxWrapper>
                        <CheckBox  {...register("redirect")}   id="checkbox" type="checkbox" />
                        <CheckBoxLabel htmlFor="checkbox" />
                      </CheckBoxWrapper>
                    </div>
                  </div>
                </div>
              )}
            </TabPanel> */}

          </SwipeableViews>

          {get(getUserInfo, "data.status") === "FREE" ? (
            ""
          ) : (
            <div className="add-footer" style={{ justifyContent: "flex-end" }}>
              <button className="add-create" type="submit">
                {isLoading ? (
                  <ReactLoading
                    type="bubbles"
                    color="#fff"
                    height={40}
                    width={40}
                  />
                ) : (
                  "Yaratish"
                )}
              </button>
            </div>
          )}
         </form>
    </Box>
  );
}
