/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./AdminHeader.css";
import adminLogo from "../../../src/Assets/Img/LogoImg.png";
import { useState } from "react";
import Modal from "react-modal";
import Modal101 from "react-modal";
import exitPic from "../../Assets/Img/exit.png";
import trueIcon from "../../Assets/Img/checkmark.png";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation } from "swiper";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import MenuBtn from "../../Assets/SVG/MenuBtn";
import ExitModal from "../ExitModal/ExitModal";
import Profile from "../../Assets/SVG/Profile";
import Analitika from "../../Assets/SVG/Analitika";
import Murojaat from "../../Assets/SVG/Murojaat";
import Account from "../../Assets/SVG/Account";
import { get } from "lodash";
import close from "../../Assets/Img/close.png";
import arrow from "../../Assets/Img/arow.png";
import CustomizedMenus from "./mobile-burger";
import GridViewIcon from "@mui/icons-material/GridView";
import { GetUserInfo } from "../../services/query/get-user-info";
import ModalMui from '@mui/material/Modal';
import Button from '@mui/material/Button';

const paths = ["edit", "settings", "premium"];
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "none",
  },
};
const ModalStyle = {
  position: 'absolute',
  top: "50%",
  left: "36%",
  transform: 'translate(-50%, -50%)',
  width: 296,
  minHeight: "100vh",
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function ModalTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`action-tabpanel-${index}`}
      aria-labelledby={`action-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </Typography>
  );
}

ModalTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `action-tab-${index}`,
    "aria-controls": `action-tabpanel-${index}`,
  };
}

const AdminHeader = ({ username, profileLists }) => {
  const { pathname } = useLocation();
  const activeLink = pathname.includes("");
  const handleAddClass = (evt) => {
    const links = document.querySelectorAll(".dashboard__link-active");
    links.forEach((link) => {
      link.classList.remove("dashboard__link-active");
    });
    evt.currentTarget.classList.add("dashboard__link-active");
  };

  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }
  function afterOpenModal() {
    subtitle.style.color = "#f00";
  }
  function closeModal() {
    setIsOpen(false);
  }
  const [modalIsOpen1, setIsOpen1] = useState(false);
  function openModal1() {
    setIsOpen1(true);
  }
  function afterOpenModal1() {
    subtitle.style.color = "#f00";
  }
  function closeModal1() {
    setIsOpen1(false);
  }
  const [premiumModalOpen, setPremiumModalOpen] = useState(false);
  function openPremiumModal() {
    setPremiumModalOpen(!premiumModalOpen);
  }
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeIndex = (index) => {
    setValue(index);
  };
  const history = useNavigate();
  const logout = () => {
    localStorage.clear();
    history("/loginpage");
    window.location.reload();
  };
  const [show, setShow] = useState(false);
  const handleOpen1 = () => {
    setShow(!show); // Toggle accordion
  };
  const handeleProfileSelection = (singleProfile) => {
    history(`/${get(singleProfile, "username")}`);
    // window.location.reload();
  };
  const filteredSingleProfile = profileLists?.filter(
    (listItem) => listItem?.username === username
  );

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <div className="admin-header ">
      <div className="container">
        <Link to={`${username}`} className="admin-logo">
          <img src={adminLogo} alt="" className="admin-brand" />
        </Link>
        <ul className="admin-header-list">
          <li
            className="admin-header-item"
            style={{
              borderBottom:
                pathname === `/${username}` ? "2px solid #512da8" : "none",
            }}
          >
            <Link to={`${username}`} className="admin-header-link">
              Ko’rib chiqish
            </Link>
          </li>
          <li
            className="admin-header-item"
            style={{
              borderBottom: pathname.includes("edit")
                ? "2px solid #512da8"
                : "none",
            }}
          >
            <Link to={`${username}/edit`} className="admin-header-link">
              Tahrirlash
            </Link>
          </li>
          <li
            className="admin-header-item"
            style={{
              borderBottom: pathname.includes("settings")
                ? "2px solid #512da8"
                : "none",
            }}
          >
            <Link className="admin-header-link" to={`${username}/special`}>
              Sozlamalar
            </Link>
          </li>

          <li className="admin-header-item">
            <Link className="admin-header-link" to={`${username}/modal-pro-page`} >
              Yangilash
            </Link></li>


        </ul>
        <Button className="admin-menu-btn" onClick={handleOpen}>          <GridViewIcon />
        </Button>
        <ModalMui
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <Box sx={ModalStyle}>
            <ul className="nav-lists">
              <button onClick={handleClose} className="close-menu">
                <img src={close} alt="Close Logo" className="admin-menu-close" />
              </button>
              <section className="admin-dashboard">
                <div className="dashboard-top">
                  <div className="dashboard-image-wrapper">
                    <img
                      src={`https://api-toplink.uz${get(filteredSingleProfile, "[0].profile_img") ||
                        get(profileLists, "[0].profile_img")}`} alt="avatar" className="dashboard-avatar"/>
                  </div>
                  <div className="dashboard-box" onClick={handleOpen1}>
                    <h2 className="dashboard-name">
                      {get(filteredSingleProfile, "[0].username")}
                    </h2>
                    <button className="dashboard__sign">
                      {show ? (
                        <i className="fa fa-chevron-up" aria-hidden="true"></i>
                      ) : (
                        <i className="fa fa-chevron-down" aria-hidden="true"></i>
                      )}
                    </button>
                  </div>

                  {show && (
                    <div className="dashboard-scroll">
                      <div className="accordian-body">
                        <Link
                          className="dashboard__links"
                          to={`/${username}/user-profile`}
                          onClick={handleClose}
                        >
                          <img src={arrow} className="dashboard__img" alt="" />
                          <span>Qo'shish</span>
                        </Link>
                      </div>
                      {get(profileLists, "length") > 0 &&
                        profileLists?.map(
                          (item) =>
                            get(item, "username") !== username && (
                              <Link
                                to={`/${get(item, "username")}`}
                                key={get(item, "id")}
                                onClick={handleClose}
                              >
                                <div
                                  className="profile-list"
                                  onClick={() => handeleProfileSelection(item)}
                                >
                                  <div className="image-wrapper">
                                    <img
                                      src={`https://api-toplink.uz${get(
                                        item,
                                        "profile_img"
                                      )}`}
                                      alt={get(item, "username")}
                                    />
                                  </div>
                                  <span>{get(item, "username")}</span>
                                </div>
                              </Link>
                            )
                        )}
                    </div>
                  )}
                </div>

                <ul className="admin-dashboard-list">
                  <li className="admin-dashboard-item">
                    <Link
                      className="admin-dashboard-link"
                      to={`/${username}`}
                      onClick={handleClose}
                    >
                      <Profile />
                      Profile
                    </Link>
                  </li>
                  <li className="admin-dashboard-item">
                    <Link
                      className="admin-dashboard-link"
                      to={`${username}/analytic`}
                      onClick={handleClose}
                    >
                      <Analitika />
                      Analitika
                    </Link>
                  </li>
                  <li className="admin-dashboard-item">
                    <Link
                      className="admin-dashboard-link"
                      to={`${username}/refertofriend`}
                      onClick={handleClose}
                    >
                      <Murojaat />
                      Do’stlarga murojat
                    </Link>
                  </li>
                  <li className="admin-dashboard-item">
                    <Link
                      className="admin-dashboard-link"
                      to={`${username}/account`}
                      onClick={handleClose}
                    >
                      <Account />
                      Akkount
                    </Link>
                  </li>
                  <li className="admin-dashboard-item"                       onClick={handleClose}
>
                    <Link
                      className="admin-dashboard-link"
                      onClick={() => openPremiumModal()}
                      to="#"
                    >
                      <img src={exitPic} alt="" className="admin-dashboard-pic" />
                      <p className="admin-dashboard-text admin-dashboard-red">
                        Chiqish
                      </p>
                    </Link>
                  </li>
                  <li className="admin-dashboard-item">
                    <button onClick={openModal} className="admin-dashboard-btn">
                      Yangilash
                    </button>
                  </li>
                </ul>
              </section>
            </ul>
          </Box>
        </ModalMui>
        {/* </div> */}
        <div className="drop-btn">
          <CustomizedMenus {...{ username }} />
        </div>
      </div>
      <ExitModal
        show={premiumModalOpen}
        className="exit-modal"
        contentLabel="Example Modal"
      >
        <button
          onClick={() => setPremiumModalOpen()}
          className="exit-close-menu"
        >
          &times;
        </button>
        <div className="exit-header">
          <h4>Chiqish</h4>
          <p>
            Chiqishni xohlaysizmi ? Hisobingizdan chiqib ketsangiz, profilingiz
            va barcha ma’lumotlaringizni qayta kiritishingiz kerak bo‘ladi
          </p>
        </div>
        <div className="exit-footer">
          <button onClick={() => setPremiumModalOpen()}>Yo'q </button>
          <button onClick={logout}>Ha</button>
        </div>
      </ExitModal>
    </div>
  );
};

export default AdminHeader;
