import "./Premium.css";
import { Link, useParams } from "react-router-dom";
 import React from "react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { useTheme } from "@mui/material/styles";
 
 
 

const Premium = () => {

 

  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

 const {username}=useParams()
  return (
    <section className="premium">
      <ul className="premium-list">
        <li className="premium-item">
          <p className="premium-text">Foydalanuvchi domeni</p>
          <div className="premium-box">
            <h3 className="premium-title">
              Ushbu xususiyat va boshqa imkoniyatlardan foydalanish uchun
              Biznesga yangilang!
            </h3>
            <Link className="pro-link"  to={`/${username}/modal-pro-page`} >
            Hozir yangilang
            </Link>
          </div>
        </li>
        <li className="premium-item">
          <p className="premium-text">Foydalanuvchi domeni</p>
          <div className="premium-box">
            <h3 className="premium-title">
              Ushbu xususiyat va boshqa imkoniyatlardan foydalanish uchun
              Biznesga yangilang!
            </h3>
            <Link className="pro-link"  to={`/${username}/modal-pro-page`} >
            Hozir yangilang
            </Link>
          </div>
        </li>
        <li className="premium-item">
          <p className="premium-text">Foydalanuvchi domeni</p>
          <div className="premium-box">
            <h3 className="premium-title">
              Ushbu xususiyat va boshqa imkoniyatlardan foydalanish uchun
              Biznesga yangilang!
            </h3>
            <Link className="pro-link"  to={`/${username}/modal-pro-page`} >
            Hozir yangilang
            </Link>
          </div>
        </li>
      </ul>
 
    </section>
  );
};

export default Premium;
