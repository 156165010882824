import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { GetReferalRedirect } from "../../services/query/referal-redirect";

const ReferalRedirect = () => {
  const { refId } = useParams();
  const { isSuccess } = GetReferalRedirect(refId);
  const navigate = useNavigate();
  useEffect(() => {
    if (isSuccess) {
      navigate("/register-page?redirect");
    }
  }, [isSuccess, navigate]);

  return <div>...Redirecting</div>;
};

export default ReferalRedirect;
