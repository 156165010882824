import React, { useContext, useEffect } from "react";
import AdminFooter from "../Components/AdminFooter/AdminFooter";
import AdminHeader from "../Components/AdminHeader/AdminHeader";
import Dashboard from "../Components/Dashboard/Dashboard";
import { GetProfiles } from "../services/query/get-profiles";
import { useLocation, useNavigate } from "react-router-dom";
import { get } from "lodash";
import { Box } from "@mui/material";
import ReactLoading from "react-loading";
import UserProfil from "../Components/UserProfil/UserProfil";
import { GetBackgroundColor } from "../services/query/get-background-color";
import { ChangeProfileContext } from "../Context/ProfileChangeContext";
import { GetProfilesDetails } from "../services/query/get-profile-details";
import { GetCustom } from "../services/query/get-custom";
import { MainLayout } from "./layout-index";
import { GetCustomDetail } from "../services/query/get-custom-detail";
import ModalProPage from "../Pages/ModalProPage/ModalProPage";

const Layout = ({ children }) => {
  const { pathname } = useLocation();
  const history = useNavigate();
  const userId = localStorage.getItem("id");
  const [isChangeProfile] = useContext(ChangeProfileContext);
  const { data, isSuccess, isFetching } = GetProfiles(userId, isChangeProfile);
   useEffect(() => {
    if (data) {
      if (pathname === "/" || pathname === "") {
        history(`/${get(data, "[0].username")}`);
      }
    }
  }, [data, pathname, history]);

  const username = pathname.substring(
    pathname.indexOf("/") + 1,
    pathname.indexOf("/") !== pathname.lastIndexOf("/")
      ? pathname.lastIndexOf("/")
      : pathname.length
  );

  /************************************* */

  const themeColors = GetBackgroundColor(username);
  const profile = GetProfilesDetails(username);
  let id = get(profile, "data[0].id");
  const getCustomId = GetCustomDetail(id);
  const cusId = get(getCustomId, "data[0].id")
  const custom = GetCustom(cusId);
 
  return (
    <div>
      {!isFetching && isSuccess ? (
        <>
          <AdminHeader {...{ username }} profileLists={data} />
          <div
            className={`edit-page
              adminProfile-page  
              adminProfile-page-${
                pathname === `/${username}`
                  ? themeColors?.data?.background_color
                  : ""
              }`}
          >
            { pathname === `/${username}/modal-pro-page` ?
            (<ModalProPage />)
            :
            (pathname !== `/${username}/user-profile`)
            ? (
              <MainLayout {...{ custom }}> 
              <main className={` main   
              ${  pathname === `/${username}` 
              ?
               ((themeColors?.data?.background_color === "classic" ? 'main-style' : themeColors?.data?.background_color === ""  ? 'main-style' 
               : "") ) : ""   }`}
              // style={{
              //   background: pathname === `/${username}`?`${ custom?.data?.font_style1}`:"none",
              //   opacity:    pathname === `/${username}`? `${custom?.data?.opacity}`:"none"
              //   }}
                >
                <div className="container">
                  <Dashboard profileLists={data} {...{ username }} />
                  {children}
                </div>
              </main>
              </MainLayout>
            ) 
            : (
              <UserProfil />
            )}
          </div>
          <AdminFooter />
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
            backgroundColor: "#6036b2",
          }}
        >
          <ReactLoading
            type="spinningBubbles"
            color="#fff"
            height={100}
            width={100}
          />
        </Box>
      )}
    </div>
  );
};

export default Layout;
