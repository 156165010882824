import { useState } from "react";
import { createContext } from "react";

const ImgClickContext = createContext();

function ImgLinkContextFunction({children}){
    const [imgClick,setImgClick] = useState(false);

    return(
        <ImgClickContext.Provider value={[imgClick,setImgClick]} >
            {children}
        </ImgClickContext.Provider>
    )
}

export {ImgClickContext, ImgLinkContextFunction};