import React from "react";
import Modal from "react-modal";
import { useLocation, useNavigate } from "react-router-dom";
import { ChangeSubscriptionUpdate } from "../../../services/mutations/change-subscription-update";
import { GetUserSubscribe } from "../../../services/query/get-user-subscribe";
import "./SubscribeModal.css";
import { get } from "lodash";
import { GetUserInfo } from "../../../services/query/get-user-info";
const customStyles = {
  content: {
    width: "25.5%",
    height: "29.5%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    padding: "35px 18px",
  },
};

export default function SubscribeModal() {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function openModal() {
    setIsOpen(true);
  }
  function afterOpenModal() {
    // subtitle.style.color = '#f00';
  }
  function closeModal() {
    setIsOpen(false);
  }
  const location = useLocation();
  const username = location?.pathname?.split("/")[1];
  const subsId = GetUserSubscribe(localStorage.getItem("id"));
  const { mutate } = ChangeSubscriptionUpdate(get(subsId, "data[0].id"));
  const history = useNavigate();
  const getUserInfo = GetUserInfo(localStorage.getItem("id"));
   const handleSubscriptionUpdate = (values) => {
    const newValues = {
      user: localStorage.getItem("id"),
      tarif: localStorage.getItem("tarifId"),
    };
    mutate(
      { ...newValues },
      subsId,
      {
        onSuccess: () => {
          // toast.success("Ranglar muvaffaqiyatli tahrirlandi!", {
          //   position: "top-right",
          // });
          // alert("alo success")
        },
      },
      history(`/${username}/analyticNew`)
    );
  };

 
  return (
    <div className="subscribe-card">
      <button className="subscribe-choose"
      //  className={` ${getUserInfo?.data?.status === "FREE" ? "modal-pro-btn-active" : "subscribe-choose"} `} 
      onClick={openModal}>
        Tarifni Tanlash
      </button>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal">
        <div className="subscribe-box">  Hozir {getUserInfo?.data?.status} tarifdasiz.
          Haqiqatda ham tarifni o'zgartirishga rozimisiz ?
        </div>
        <div className="subscribe-buttons">
          <button className="subscribe-btn" onClick={closeModal}>
            Yo'q
          </button>
          <div onClick={handleSubscriptionUpdate} className="subscribe-btn">
            <span> Ha</span>
          </div>
        </div>
      </Modal>
    </div>
  );
}
