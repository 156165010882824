import "./People.css";
import PeopleImg from "../../Assets/Img/PeopleImg.png"
import Fade from 'react-reveal/Fade';

const People =()=>{
    return(
        <section className="people">
                 <Fade bottom>
            <div className="container">
            <div className="people-left">
                <h2 className="people-title">
                Bizni minglab odamlar sevadi
                </h2>
                <p className="people-text">
                Barcha kontentingizni hamma joyda baham ko'ring. Toplink havolangiz qayerda ishlatilishiga cheklov yo'q.                </p>
            </div>
            <div className="people-right">
                <img src={PeopleImg} alt="PeopleImg" className="people-img" />
            </div>
            
            </div>
            </Fade>
        </section>
    )
}

export default People;