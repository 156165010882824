import React from "react";
import { get } from "lodash";
import { useForm } from "react-hook-form";
import { ChangeCustomPutPic } from "../../../services/mutations/change-custom-put-pic";
import { GetCustomDetail } from "../../../services/query/get-custom-detail";
import { GetCustom } from "../../../services/query/get-custom";
import { ToastContainer, toast } from "react-toastify";
import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { GetUserInfo } from "../../../services/query/get-user-info";

const ColorFonts = (props) => {
  let profileId = get(props, "profile.data[0].id");
  const getCustomId = GetCustomDetail(profileId);
  const customId = get(getCustomId, "data[0].id");
  const { mutate } = ChangeCustomPutPic(customId);
  const custom = GetCustom(customId);
  const { register, handleSubmit, watch,reset } = useForm({});
  useEffect(() => {
    const defaultValue = {
      fonTheme: custom?.data?.font_style1,
      selectFont: custom?.data?.font_style,
      buttonTextColor: custom?.data?.button_text_color,
      buttonColor: custom?.data?.button_color,
      textColor:  custom?.data?.text_color,
    };
    reset(defaultValue);
  }, [custom.isFetching]);
  
  const handleChangeFon = (values) => {
    const newValues = {
      profile: profileId,
      font_style1: values.fonTheme,
      font_style: values.selectFont,
      button_text_color: values.buttonTextColor,
      button_color: values.buttonColor,
      text_color: values.textColor,
    };
    mutate(
      { ...newValues },
      {
        onSuccess: () => {
          toast.success("Ranglar muvaffaqiyatli tahrirlandi!", {
            position: "top-right",
          });
        },
      }
    );
  };

  const { username } = useParams();
  const getUserInfo = GetUserInfo(localStorage.getItem("id"));


  return (
    <div className="special-color">
         {get(getUserInfo, "data.status") === "BIZNES" ? (
              <div className="custom-styles">
              <ToastContainer />
              <div className="special-top">Ranglar & Font</div>
              <form className="color-form" onSubmit={handleSubmit(handleChangeFon)}>
                <div style={{ width: "100%" }}>
                  <ul className="color-list">
                    <li className="color-item">
                      <label className="color-label">Fon rangi</label>
                      <div>
                        <input
                          type="color"
                          className="color-input"
                           {...register("fonTheme")}
                        />
                        <p>{watch("fonTheme") ? watch("fonTheme") : "#000000" }</p>
                      </div>
                    </li>
                    <li className="color-item">
                      <label className="color-label">Button rangi</label>
                      <div>
                        <input
                          type="color"
                          {...register("buttonColor")}
                          className="color-input"
                        />
                        <p>{watch("buttonColor") ?  watch("buttonColor") : "#000000"}</p>
                      </div>
                    </li>
                    <li className="color-item">
                      <label className="color-label">Button matni rangi</label>
                      <div>
                        <input
                          type="color"
                          {...register("buttonTextColor")}
                          className="color-input"
                        />
                        <p>{watch("buttonTextColor") ? watch("buttonTextColor") : "#000000"}</p>
                      </div>
                    </li>
                    <li className="color-item">
                      <label className="color-label">Matn rangi</label>
                      <div>
                        <input
                          type="color"
                          {...register("textColor")}
                          className="color-input"
                        />
                        <p>{watch("textColor") ? watch("textColor") : "#000000"}</p>
                      </div>
                    </li>
                  </ul>
                  <div className="special-font">
                    <label className="color-label">Font</label>
                    <select className="special-select" {...register("selectFont")}>
                      <option
                        value="Fontni tanlang"
                        disabled
                        className="special-option">
                        Fontni tanlang
                      </option>
                      <option value="Alegreya" className="special-option">
                        Alegreya
                      </option>
                      <option value="Alfa-Slab-One" className="special-option">
                        Alfa Slab One
                      </option>
                      <option value="Boogaloo" className="special-option">
                        Boogaloo
                      </option>
                      <option value="Caveat" className="special-option">
                        Caveat
                      </option>
                      <option value="Cookie" className="special-option">
                        Cookie
                      </option>
                      <option value="Courgette" className="special-option">
                        Courgette
                      </option>
                      <option value="FiraSans" className="special-option">
                        Fira Sans
                      </option>
                      <option value="GochiHand" className="special-option">
                        Gochi Hand
                      </option>
                      <option value="Kalam" className="special-option">
                        Kalam
                      </option>
                      <option value="KaushanScript" className="special-option">
                        Kaushan Script
                      </option>
                      <option value="Lalezar" className="special-option">
                        Lalezar
                      </option>
                      <option value="Lato" className="special-option">
                        Lato
                      </option>
                      <option value="LobsterTwo" className="special-option">
                        Lobster Two
                      </option>
                      <option value="LuckiestGuy" className="special-option">
                        Luckiest Guy
                      </option>
                      <option value="MarckScript" className="special-option">
                        Marck Script
                      </option>
                      <option value="MerriWeather" className="special-option">
                        Merriweather
                      </option>
                      <option value="Monoton" className="special-option">
                        Monoton
                      </option>
                      <option value="Neucha" className="special-option">
                        Neucha
                      </option>
                      <option value="Neuton" className="special-option">
                        Neuton
                      </option>
                      <option value="Orbitron" className="special-option">
                        Orbitron
                      </option>
                      <option value="Oswald" className="special-option">
                        Oswald
                      </option>
                      <option value="PlayfairDisplay" className="special-option">
                        Playfair Display
                      </option>
                      <option value="PtSerif" className="special-option">
                        PT Serif
                      </option>
                      <option value="Raleway" className="special-option">
                        Raleway
                      </option>
                      <option value="Roboto" className="special-option">
                        Roboto
                      </option>
                      <option value="RobotoCondensed" className="special-option">
                        Roboto Condensed
                      </option>
                      <option value="RobotoMono" className="special-option">
                        Roboto Mono
                      </option>
                      <option value="RobotoSlab" className="special-option">
                        Roboto Slab
                      </option>
                      <option value="TitilliumWeb" className="special-option">
                        Titillium Web
                      </option>
                      <option value="Ubuntu" className="special-option">
                        Ubuntu
                      </option>
                    </select>
                  </div>
                </div>
        
                <button type="submit" className="color-btn"style={{cursor: "pointer"}}>
                  Yaratish
                </button>
              </form>
              </div>
)
    :
    <div>
    <div className="custom-style custom-styles" style={{ filter: `blur(12px)`, 
    zIndex:-1 }}>
       <ToastContainer />
       <div className="special-top">Ranglar & Font</div>
       <form className="color-form" onSubmit={handleSubmit(handleChangeFon)}>
         <div style={{ width: "100%" }}>
           <ul className="color-list">
             <li className="color-item">
               <label className="color-label">Fon rangi</label>
               <div>
                 <input
                   type="color"
                   className="color-input"
                    {...register("fonTheme")}
                 />
                 <p>{watch("fonTheme") ? watch("fonTheme") : "#000000" }</p>
               </div>
             </li>
             <li className="color-item">
               <label className="color-label">Button rangi</label>
               <div>
                 <input
                   type="color"
                   {...register("buttonColor")}
                   className="color-input"
                 />
                 <p>{watch("buttonColor") ?  watch("buttonColor") : "#000000"}</p>
               </div>
             </li>
             <li className="color-item">
               <label className="color-label">Button matni rangi</label>
               <div>
                 <input
                   type="color"
                   {...register("buttonTextColor")}
                   className="color-input"
                 />
                 <p>{watch("buttonTextColor") ? watch("buttonTextColor") : "#000000"}</p>
               </div>
             </li>
             <li className="color-item">
               <label className="color-label">Matn rangi</label>
               <div>
                 <input
                   type="color"
                   {...register("textColor")}
                   className="color-input"
                 />
                 <p>{watch("textColor") ? watch("textColor") : "#000000"}</p>
               </div>
             </li>
           </ul>
           <div className="special-font">
             <label className="color-label">Font</label>
             <select className="special-select" {...register("selectFont")}>
               <option
                 value="Fontni tanlang"
                 disabled
                 className="special-option">
                 Fontni tanlang
               </option>
               <option value="Alegreya" className="special-option">
                 Alegreya
               </option>
               <option value="Alfa-Slab-One" className="special-option">
                 Alfa Slab One
               </option>
               <option value="Boogaloo" className="special-option">
                 Boogaloo
               </option>
               <option value="Caveat" className="special-option">
                 Caveat
               </option>
               <option value="Cookie" className="special-option">
                 Cookie
               </option>
               <option value="Courgette" className="special-option">
                 Courgette
               </option>
               <option value="FiraSans" className="special-option">
                 Fira Sans
               </option>
               <option value="GochiHand" className="special-option">
                 Gochi Hand
               </option>
               <option value="Kalam" className="special-option">
                 Kalam
               </option>
               <option value="KaushanScript" className="special-option">
                 Kaushan Script
               </option>
               <option value="Lalezar" className="special-option">
                 Lalezar
               </option>
               <option value="Lato" className="special-option">
                 Lato
               </option>
               <option value="LobsterTwo" className="special-option">
                 Lobster Two
               </option>
               <option value="LuckiestGuy" className="special-option">
                 Luckiest Guy
               </option>
               <option value="MarckScript" className="special-option">
                 Marck Script
               </option>
               <option value="MerriWeather" className="special-option">
                 Merriweather
               </option>
               <option value="Monoton" className="special-option">
                 Monoton
               </option>
               <option value="Neucha" className="special-option">
                 Neucha
               </option>
               <option value="Neuton" className="special-option">
                 Neuton
               </option>
               <option value="Orbitron" className="special-option">
                 Orbitron
               </option>
               <option value="Oswald" className="special-option">
                 Oswald
               </option>
               <option value="PlayfairDisplay" className="special-option">
                 Playfair Display
               </option>
               <option value="PtSerif" className="special-option">
                 PT Serif
               </option>
               <option value="Raleway" className="special-option">
                 Raleway
               </option>
               <option value="Roboto" className="special-option">
                 Roboto
               </option>
               <option value="RobotoCondensed" className="special-option">
                 Roboto Condensed
               </option>
               <option value="RobotoMono" className="special-option">
                 Roboto Mono
               </option>
               <option value="RobotoSlab" className="special-option">
                 Roboto Slab
               </option>
               <option value="TitilliumWeb" className="special-option">
                 Titillium Web
               </option>
               <option value="Ubuntu" className="special-option">
                 Ubuntu
               </option>
             </select>
           </div>
         </div>
 
         <button type="submit" className="color-btn" style={{cursor: "pointer"}}>
           Yaratish
         </button>
       </form>
       </div>
       
       <div className=" color-premiums" style={{zIndex: "11111"}}>
             <p>Ushbu funksiya uchun Biznesga yangilang!</p>
             <Link className="pro-link" to={`/${username}/modal-pro-page`}>
               Hozir yangilang
             </Link>
       
         </div>
         </div>

         }
    </div>

  );
};

export default ColorFonts;
