import { useParams } from "react-router-dom";
import "./AdminProfil.css";
import { useContext } from "react";
import MapsIcon from "../../Assets/Img/MapsIcon";
import { ButtonTextContext } from "../../Context/ButtonTextContext";
import { Animated } from "react-animated-css";
import { AnimationContext } from "../../Context/AnimationContext";
import { SocialIcon } from "react-social-icons";
import { AdminSocialList, MainBlok } from "./index.style";
import { Paper } from "@mui/material";
import defaultImg from "../../Assets/default.svg";
import { GetProfilesDetails } from "../../services/query/get-profile-details";
import { get } from "lodash";
import { GetLinkLists } from "../../services/query/get-link-lists";
import {
  AdminProfilSkeletonInput,
  AdminProfilSkeletonCard,
} from "./index.skeleton";
import { GetBackgroundColor } from "../../services/query/get-background-color";
import useMediaQuery from "@mui/material/useMediaQuery";
import { GetCustom } from "../../services/query/get-custom";
import { GetCustomDetail } from "../../services/query/get-custom-detail";

const AdminProfil = () => {
  const [buttonTextColor] = useContext(ButtonTextContext);
  const [animation] = useContext(AnimationContext);
  const { username } = useParams();
  const { data, isSuccess, isFetching } = GetProfilesDetails(username);
  const profileLinks = GetLinkLists(username);
   const themeColors = GetBackgroundColor(username);
  const mediaQuery = useMediaQuery("(max-width:780px)");
  const profile = GetProfilesDetails(username);
  let id = get(profile, "data[0].id");
  const getCustomId = GetCustomDetail(id);
  const cusId = get(getCustomId, "data[0].id");
  const custom = GetCustom(cusId);

  return (
    <section className="main-right">
      {!isFetching && isSuccess ? (
        <form className="main-form">
          <input
            id="some-hijacked-input"
            className={`main-input main-input-${custom?.data?.font_style} 
            main-input-${themeColors?.data?.background_color}`}
            type="text"
            value={`https://toplink.uz/${data[0]?.username}`}
          />
          <button
            type="button"
            className={`main-btn main-btn-${themeColors?.data?.background_color}`}
            onClick={() => {
              document.getElementById("some-hijacked-input").select();
              document.execCommand("copy");
            }}
          >
            <i
              className={`fa-regular fa-copy copy-img copy-img-${themeColors?.data?.background_color}`}
            ></i>
          </button>
        </form>
      ) : (
        <AdminProfilSkeletonInput />
      )}

      <Paper
        sx={{
          p: 3,
          mt: 5,
          mb: 5,
          borderRadius: "12px",
          width: mediaQuery ? "100%" : "85%",
        }}
        elevation={1}
      >
        {!isFetching && isSuccess ? (
          <div className="main-bottom">
            <div className="adminpage-image-wrapper">
              <img
                src={
                  `https://api-toplink.uz${get(data, "[0].profile_img")}` ||
                  defaultImg
                }
                alt="avatar"
                className="main-avatar"
              />
            </div>

            <MainBlok {...{ custom }}>
              <h2
                className={`main-name main-name-${custom?.data?.font_style} main-name-${themeColors?.data?.background_color}`}
              >
                {get(data, "[0].display_name") ||
                  "@" + get(data, "[0].username")}
              </h2>
              {get(data, "[0].location") && (
                <div
                  className={`main-map main-map-${themeColors?.data?.background_color}`}
                >
                  <MapsIcon />
                  <p
                    className={`main-text main-text-${custom?.data?.font_style} 
                          main-text-${themeColors?.data?.background_color}`}
                  >
                    {get(data, "[0].location")}
                  </p>
                </div>
              )}
              <p
                className={`main-subname main-subname-${custom?.data?.font_style} main-subname-${themeColors?.data?.background_color}`}
              >
                {get(data, "[0].bio")}
              </p>
            </MainBlok>
          </div>
        ) : (
          <AdminProfilSkeletonCard />
        )}
      </Paper>
      <AdminSocialList {...{ custom }} {...{ buttonTextColor }}>
        {get(profileLinks, "data", []).map((link) => (
          <Animated
            key={link?.id}
            // animationIn="lightSpeedIn"
            animationOut={link?.animation}
            animationInDuration={1000}
            animationOutDuration={1000}
            isVisible={true}
            className={link?.animation}
          >
            <li
              className={`adminSocial-item
         adminSocial-item-${themeColors?.data?.background_color}`}
            >
              <a
                target="_blank"
                href={`${link.icon_url}`}
                className={`adminSocial-link adminSocial-link-${custom?.data?.button_shape} adminSocial-link-${themeColors?.data?.background_color}`}
                rel="noreferrer">
                <div
                  className={`adminSocial-boxes
                    adminSocial-boxes-${themeColors?.data?.background_color}`}
                >
                  <div
                    className={`adminSocial-box1 
                            adminSocial-box1-${themeColors?.data?.background_color}`}>
                        {link?.thumbnail===null ? <SocialIcon target="_blank" datasetId={link?.guid} style={{ fontSize: "10px" }} url={link?.icon_url} />
:                        <img src={`https://api-toplink.uz${link?.thumbnail}`} alt={link?.thumbnail}  style={{width:"32px",height:"32px", borderRadius:"50%"}}/>}
                  </div>
                </div>
                <div
                  className={`adminSocial-name  adminSocial-name-${custom?.data?.font_style}
                          adminSocial-name-${themeColors?.data?.background_color}`}
                >
                  {link?.icon_name}
                </div>
              </a>
            </li>
          </Animated>
        ))}
      </AdminSocialList>
    </section>
  );
};

export default AdminProfil;
