import "./SpecialDomen.css";
import specialDomen from "../../Assets/Img/specialDomen.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useContext, useState } from "react";
import { freeThemeList, proThemeList, themeList } from "../Settings/theme-list";
import { GetProfilesDetails } from "../../services/query/get-profile-details";
import { toast } from "react-toastify";
import { ChangeProfileContext } from "../../Context/ProfileChangeContext";
import { useChangeColor } from "../../services/mutations/change-color";
import ColorFonts from "./components/color-fonts";
import ButtonCustomize from "./components/button-customize";
import BackgroundChanger from "./components/background-changer";
import MirrorCodeCustom from "./components/mirror-code";
import { GetUserInfo } from "../../services/query/get-user-info";
import { get } from "lodash";
import styled from "styled-components";

 
export const MainLayout = styled.div`
@media(max-width:420px){
  .ReactModal__Content--after-open{
    width: 88%;
    transform: translate(-6%, -1%) !important;
    }
  .css-wxvlna img{
    width: 100px;
    height: 100px;
  } 
    `
 
const SpecialDomen = () => {
  const { username } = useParams();
  const profile = GetProfilesDetails(username);
  const history = useNavigate();
  const [isChangeProfile, setIsChangeProfile] =
    useContext(ChangeProfileContext);
  const mutateColor = useChangeColor(username).mutate;
  const getUserInfo = GetUserInfo(localStorage.getItem("id"));

  /************************************** change theme ********************************** */

  const handleThemeChangeColor = async (colorName) => {
    mutateColor(
      { background_color: colorName },
      {
        onSuccess: () => {
          setIsChangeProfile(!isChangeProfile);
          history(`/${username}`);
        },
        onError: () => {
          toast.error("Nimadir xato!");
        },
      }
    );
  };

  

  return (
    <div className="special">
      <section className="settings">
        <h2 className="settings-title">Ilovalar</h2>
    
        {get(getUserInfo, "data.status") === "FREE" ? (
          <ul className="settings-list">
            {freeThemeList &&
              freeThemeList?.map((item) => (
                <li
                  className="settings-item"
                  key={item?.id}
                  onClick={() => handleThemeChangeColor(item?.alt)}
                >
                  <img
                    src={item?.img}
                    alt={item?.alt}
                    className="settings-img"
                  />
                </li>
              ))}
            {proThemeList &&
              proThemeList?.map((item) => (
                <li
                  className="settings-item"
                  key={item?.id}
                  // onClick={() => handleThemeChangeColor(item?.alt)}
                >
                  <Link
                    to={`/${username}/modal-pro-page`}
                    className="settings-btn settings-pro"
                  >
                    <img
                      src={item?.img}
                      alt={item?.alt}
                      className="settings-img"
                    />
                    <div className="pro-text">Premium</div>
                  </Link>
                </li>
              ))}
            <li className="settings-item">
              <Link
                to={`/${username}/modal-pro-page`}
                className="settings-btn settings-pro"
              >
                <img
                  src={specialDomen}
                  alt="specialDomen"
                  className="settings-img"
                />
                <div className="pro-text">Premium</div>
              </Link>
            </li>
          </ul>
        ) : (
          <ul className="settings-list">
            {themeList.map((item, i) => (
              <li
                className="settings-item"
                key={i}
                onClick={() => handleThemeChangeColor(item?.alt)}
              >
                <p className="settings-btn">
                  <img
                    src={item?.img}
                    alt={item?.alt}
                    className="settings-img"
                  />
                </p>
              </li>
            ))}
            <li className="settings-item">
              <Link to="#" className="settings-btn">
                <img
                  src={specialDomen}
                  onClick={() => window.scrollTo({ top: 300 })}
                  alt=""
                  className="settings-img"
                />
              </Link>
            </li>
          </ul>
        )}
      </section>
      {/* {get(getUserInfo, "data.status") === "FREE" ? (
        ""
      ) : ( */}
        <div className="special-form">
            <ColorFonts {...{ profile }} />
          <div className="special-blok special-bloks">
                <BackgroundChanger {...{ profile }} />
                <ButtonCustomize {...{ profile }} />  
          </div>
          {/* <MirrorCodeCustom {...{ profile }} /> */}
        </div>
      {/* )} */}
    </div>
  );
};

export default SpecialDomen;
