import "./ReferToFriend.css";
import { toast, ToastContainer } from "react-toastify";
import { GetReferals } from "../../services/query/get-referals";
import { useForm } from "react-hook-form";
import defaultImg from "../../Assets/default.svg";
import { useCreateReferal } from "../../services/mutations/create-referal";
import { Box, Stack } from "@mui/material";
import { get } from "lodash";
import LockIcon from "@mui/icons-material/Lock";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import ReactLoading from "react-loading";

const ReferToFriend = () => {
  const validationSchema = yup.object({
    email: yup
      .string()
      .email("Email to'g'ri kiritilmadi!")
      .required("Email kiritilmadi!"),
  });
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const { mutate, isLoading } = useCreateReferal();
  const [isSended, setIsSended] = useState(false);
  const { data, isFetching } = GetReferals(isSended);
  const handleSendReferral = ({ email }) => {
    mutate(
      { recipient: email },
      {
        onSuccess: () => {
          toast.success("Referal muvaffaqiyatli jo'natildi!");
          setIsSended(!isSended);
        },
        onError: (err) => {
           toast.error(get(err, "response.data.message"));
        },
      }
    );
  };
  const isDisabled = get(data, "number_of_recommendation") > 10;
  return (
    <section className="refer">
      <div className="refer-top">
        Doʻstingizga murojaat qiling va u roʻyxatdan oʻtganida bir oylik bepul
        Premiumga  ega boʻling!
      </div>
      <div className="refer-medium">
        <>
          <h2 className="refer-title">Havolalarni bosish</h2>
          <form
            className="refer-form"
            onSubmit={handleSubmit(handleSendReferral)}
          >
            <div style={{ width: "80%" }}>
              <input
                placeholder="Elektron pochta"
                className="refer-input"
                {...register(isDisabled ? "wdwdw" : "email")}
                disabled={isDisabled}
              />
              {errors?.email && (
                <p style={{ color: "red" }}>{errors?.email?.message}</p>
              )}
            </div>
            <button className="refer-btn" type="submit">
              {isLoading ? (
                <ReactLoading
                  type="bubbles"
                  color="#fff"
                  height={40}
                  width={40}
                />
              ) : (
                "Taklif qilish"
              )}
            </button>
          </form>
        </>
        {isDisabled && (
          <div className="disabled-overlay">
            <LockIcon /> Takliflar soni 10 taga yetdi!
          </div>
        )}
      </div>
      <div className="refer-bottom">
        <div className="refer-box">Referallar</div>
        <div className="refer-footer">
          <div className="refer-item refer-items">
            <div className="refer-left">Referallar</div>
            <div className="refer-right">
              <div className="refer-box1">Yuborildi</div>
              <div className="refer-box2">Holati</div>
            </div>
          </div>
          <ul className="refer-list">
            {!isFetching ? (
              get(data, "data", []).map((item) => (
                <li className="refer-item">
                  <div className="refer-left">
                    <div className="img-wrapper">
                      <img
                        src={
                          get(item, "referal_img")
                            ? `https://api-toplink.uz${get(
                                item,
                                "referal_img"
                              )}`
                            : defaultImg
                        }
                        alt=""
                        className="refer-avatar"
                      />
                    </div>

                    <p className="refer-name">{get(item, "recipient")}</p>
                  </div>
                  <div className="refer-right">
                    <div className="refer-time">
                      {get(item, "get_sent_time")}
                    </div>
                    <div className="refer-state">{get(item, "status")}</div>
                  </div>
                </li>
              ))
            ) : (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "300px",
                }}
              >
                <ReactLoading
                  type="bars"
                  color="#6036b2"
                  height={60}
                  width={60}
                />
              </Box>
            )}
            {!isFetching && get(data, "data.length") < 1 && (
              <Stack
                fullWidth
                sx={{
                  height: "80px",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <p style={{ color: "#8898a1" }}>
                  Hozircha hech qanday referal yaratilmagan!
                </p>
              </Stack>
            )}
          </ul>
        </div>
      </div>
      <ToastContainer />
    </section>
  );
};

export default ReferToFriend;
