import { useMutation } from "react-query";
import request from "../requests";

export const useRegister = (search) =>
  useMutation("register", (data) =>
    request.public
      .post(
        `/v1/accounts/${
          search === "?redirect?step=2" ? "register/recommend-user" : "register"
        }/`,
        data
      )
      .then((res) => res.data)
  );
