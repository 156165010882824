import styled from "styled-components";



export const MainLayout = styled.div`
    
   .main-style{
    background: ${(props) => 
      (props.custom?.data?.font_style1 && props.custom?.data?.font_style1.length > 0 ?  props?.custom?.data?.font_style1 : "white"  )
    };
    background-image: url( ${(props) => 
      (props.custom?.data?.picture && props.custom?.data?.picture.length > 0 ?  props?.custom?.data?.picture : props?.custom?.data?.font_style1  )
    });
    background-size: 100% 100%;
    background-repeat: no-repeat;
    }
    `