import "./FeaturePage.css";
import futureImg from "../../Assets/Img/123q.png"
import futureImg2 from "../../Assets/Img/kontentImg.png"
import futureImg3 from "../../Assets/Img/iPhone.png"
import futureImg4 from "../../Assets/Img/futureImg4.png"
import futureImg44 from "../../Assets/Img/www.svg"
import futureImg6 from "../../Assets/Img/123.png"
import futureImg7 from "../../Assets/Img/display.png"
import futureImg8 from "../../Assets/Img/1234Img.png"
import futureImg9 from "../../Assets/Img/qw0.png"
import futureImg10 from "../../Assets/Img/qw.png"
import microImg from "../../Assets/Img/microImg.svg"
import mailImg from "../../Assets/Img/Mail.ru.svg"
import twitterImg from "../../Assets/Img/Twitter.svg"
import masterImg from "../../Assets/Img/MasterCard.svg"
import shutterImg from "../../Assets/Img/Shutterstock.png"
import sberImg from "../../Assets/Img/Sberbank.png"
import spotifyImg from "../../Assets/Img/Spotify.svg"
import statisticImg from "../../Assets/Img/loop.png"
import Nav from "../../Components/Nav/Nav";
import Footer from "../../Components/Footer/Footer";
import Fade from 'react-reveal/Fade';

const FeaturePage=()=>{
return(
<div className="future-page">
    <Nav />
    <div className="container">
        <Fade bottom>
            <div className="future-top">
                <h2 className="future-title">
                    Xususiyat
                </h2>
                <p className="future-text">
                    Bizning universal havolamiz sizning do‘stlaringizga barcha kontentingizga bir joydan kirish imkonini
                    beradi va sizga ko'proq baham ko'rish imkonini beradi.
                </p>
            </div>
        </Fade>
        <Fade bottom>
            <div className="future-bottom">
                <div className="future-left">
                    <h2 className="future-subtitle">
                        Yagona havola orqali bir nechta yo‘nalishlar
                    </h2>
                    <p className="future-subtext">
                    Har qachongidan ham ko'proq kontentni baham ko'rish uchun kuchni bo'shating. Facebook, Instagram, shaxsiy veb-saytingiz, savdo doʻkoningiz yoki ustida ishlayotgan har qanday loyihangizni bir joyda bogʻlang. Qaysi URL manzilini bio-ga qo‘yishni hech qachon hal qilishingiz shart emas.
                       </p>

                </div>
                <div className="future-right">
                    <img src={futureImg} alt="" 
                    className="future-img" />
                </div>
            </div>
        </Fade>
        <Fade bottom>
            <div className="future-bottom">
                <div className="future-left">
                    <img src={futureImg2} alt="" className="future-img" />
                </div>
                <div className="future-right">
                    <h2 className="future-subtitle">
                        Haqiqiy vaqt tahlili </h2>
                    <p className="future-subtext">
                    Profilni ko'rish va individual havolalarni bosish orqali o'sish potentsialingizni maksimal darajada oshiring. Profilingizning ishlashini tushunib, auditoriyangizni qaysi havolalar qiziqtirayotganini bilib oling.
                     </p>

                </div>

            </div>
        </Fade>
        <Fade bottom>
            <div className="future-bottom">
                <div className="future-left">
                    <h2 className="future-subtitle">
                        Profilni moslashtirish </h2>
                    <p className="future-subtext">
                    Profilingizni o'zingiz kabi noyob qiling. Shaxsiy fon rasmlari, ranglari, tugmalari va shrift uslublarini qo‘shish orqali sahifangizni uslubingiz yoki brendingizga moslashtirib shaxsiylashtiring yoki mavjud mavzularimizdan biri yordamida uni sodda saqlang. </p>

                </div>
                <div className="future-right">
                    <img src={futureImg3} alt="" className="future-img" />
                </div>
            </div>
        </Fade>
        <Fade bottom>
            <div className="future-bottom">
                <div className="future-left">
                    <img src={futureImg4} alt="" className="future-img" />
                    {/* <img src={futureImg44} alt=""  className="future-img" /> */}
                </div>
                <div className="future-right">
                    <h2 className="future-subtitle">
                        Boshqarilishi oson </h2>
                    <p className="future-subtext">
                    Profilingizdagi havolalarni qo'shing, qayta tashkil qiling va moslashtiring, so'ngra darhol (va hamma joyda) baham ko'rish uchun toplink havolangizni nusxalang va joylashtiring. Bizning foydalanuvchilarga qulay boshqaruv paneli profilingizni yaratish va tahrirlashni osonlashtiradi.</p>

                </div>

            </div>
        </Fade>
        <Fade bottom>
            <div className="future-bottom">
                <div className="future-left">
                    <h2 className="future-subtitle">
                    Har vaqt hamma joyda ishlaydi </h2>
                    <p className="future-subtext">
                    Toplink havolangiz qayerda ishlatilishiga hech qanday cheklovlar yo'q. Uni Instagram bio-ga qo'shing va uni matn, elektron pochta yoki o'zingizga kelgan boshqa joy orqali baham ko'ring. Variantlar cheksizdir.
                    </p>

                </div>
                <div className="future-right">
                <div className="statistic-right">
                <div className="img-left">
                    <div className="img-box1">
                        <img src={microImg} alt="microImg" className="micro-img" />
                        <p className="img-text">899</p>
                    </div>
                    <div className="img-box2">
                        <img src={mailImg} alt="microImg" className="micro-img" />
                        <p className="img-text">1,227</p>
                    </div>
                    <div className="img-box3">
                        <img src={twitterImg} alt="microImg" className="micro-img" />
                        <p className="img-text">1,124</p>
                    </div>
                    <div className="img-box4">
                        <img src={masterImg} alt="microImg" className="micro-img" />
                        <p className="img-text">985</p>
                    </div>
                    <div className="img-box5">
                        <img src={shutterImg} alt="microImg" className="micro-img" />
                        <p className="img-text">680</p>
                    </div>
                    <div className="img-box6">
                        <img src={sberImg} alt="microImg" className="micro-img" />
                        <p className="img-text">703</p>
                    </div>
                    <div className="img-box7">
                        <img src={spotifyImg} alt="microImg" className="micro-img" />
                        <p className="img-text">1,035</p>
                    </div>
                </div>
                <div className="img-right">
                    <img src={statisticImg} alt="statisticImg" className="statistic-img" />


                </div>

            </div></div>
            </div>
        </Fade>
        <Fade bottom>
            <div className="future-bottom">
                <div className="future-left">
                    <img src={futureImg7} alt="" className="future-img" />
                </div>
                <div className="future-right">
                    <h2 className="future-subtitle">
                    Displey rejimi </h2>
                    <p className="future-subtext">
                    O'zingizning havolalaringizni o'zingiz ko'rsating. Havolalaringizni profilingizning yuqori yoki pastki qismida piktogramma sifatida ko'rsatish imkoniyatlari bilan siz tarkibingizni qanday taqdim etishingizni to'liq sozlashingiz mumkin.  </p>

                </div>

            </div>
        </Fade> <Fade bottom>
            <div className="future-bottom">
                <div className="future-left">
                    <h2 className="future-subtitle">
                    Yagona havola orqali bir nechta yo‘nalishlar</h2>
                    <p className="future-subtext">
                    Ba'zida sevimlilarni tanlash yaxshidir. Har qanday havolalaringizni maxsus animatsiya bilan ajralib tursin va tomoshabinlarga bosishga undaydi.
                               </p>

                </div>
                <div className="future-right">
                    <img src={futureImg8} alt="" className="future-img" />
                </div>
            </div>
        </Fade>
        <Fade bottom>
            <div className="future-bottom">
                <div className="future-left">
                    <img src={futureImg9} alt="" className="future-img" />
                </div>
                <div className="future-right">
                    <h2 className="future-subtitle">
                    Haqiqiy vaqt tahlili </h2>
                    <p className="future-subtext">
                    Siz baham ko'rmoqchi bo'lgan katta narsa bormi? Bir tugmani bosish orqali toplink sahifangizni ma'lum bir havolaga yo'naltiring.               </p>

                </div>

            </div>
        </Fade> <Fade bottom>
            <div className="future-bottom">
                <div className="future-left">
                    <h2 className="future-subtitle">
                    Yagona havola orqali bir nechta yo‘nalishlar</h2>
                    <p className="future-subtext">
                    Eskizni sozlash orqali havolalaringizga maxsus narsalarni qo'shing. Tegishli eskiz bizning eng mashhur xizmatlarimiz uchun avtomatik tarzda aniqlanadi, ammo bu xususiyat sizga o'zingiznikini yuklash imkonini beradi.
                    </p>

                </div>
                <div className="future-right">
                    <img src={futureImg10} alt="" className="future-img" />
                </div>
            </div>
        </Fade>
   
    </div>
    <Footer />
</div>
)
}

export default FeaturePage;