import "./AnalyticYangilash.css"
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import Payme from "../../Assets/Img/payme.png";
import Click from "../../Assets/Img/click.png"
import Visa from "../../Assets/Img/visa2.png"
import { ChangePayment } from "../../services/mutations/change-payme";
import { useState } from 'react';

const AnalyticYangilash = () => {
   
  const tarifPrice= localStorage.getItem("tarifPrice")
  const tarifName= localStorage.getItem("tarifName")
  const currency= localStorage.getItem("currency")

  const token= localStorage.getItem("toplink_token")
  const { register, handleSubmit } = useForm({});

  const userId = localStorage.getItem("id");
  const {mutate} = ChangePayment();
 const [type, setType]=useState();
   const handleChangePayme = (transaction_type) => {
     const newValues = {
      price: tarifPrice,
      transaction_type: transaction_type,
    }
      mutate(
        { ...newValues },
        {
          onSuccess: (response) => {
            toast.success("To'lov usuli muvaffaqqiyatli tanlandi!", {
              position: "top-right",
            });
            window.location.replace(response?.generated_link);
          },
        }
      );
    }; 

  return (
    <section className="analyticYangilash">
      <div className="analyticYangilash-footer">
        <div className="analyticYangilash-top">
          <span>
            1
          </span>
          <h3 className="analyticYangilash-title">
            To'lov ma'lumotlari
          </h3>
        </div>
        <ul className="analyticYangilash-list">
          <li className="analyticYangilash-item">
            <p className="analyticYangilash-text">
              {tarifName}
            </p>
            <div className="analyticYangilash-line"></div>
            <div className="analyticYangilash-money">
              <span>
                {currency} {tarifPrice}
              </span>
              {/* <p>/ Oylik</p> */}
            </div>
          </li>
       
        </ul>
        <form>
          <div className="analyticYangilash-span analytic-code">
            <label>
              Xizmat turi
            </label>
            <div className="payme-box">
              <button type="button"  className="payme-btn" >
                <img src={Payme} onClick={(e)=> handleChangePayme(e?.target?.alt)} alt="payme" className="payme-icon" />
              </button>
              <button type="button"  className="payme-btn">
                <img src={Click} onClick={(e)=> handleChangePayme(e?.target?.alt)} alt="click" className="payme-icon" />
              </button>
              {/* <button type="button"  className="payme-btn" >
                <img src={Visa} onClick={(e)=> handleChangePayme(e?.target?.alt)} alt="visa" className="payme-icon" />
              </button> */}
            </div>
          </div>
        </form>
      </div>
    </section>
  )
}

export default AnalyticYangilash;