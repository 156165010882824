import { Box, Button, Grid, Stack, TextField } from "@mui/material";
import { get } from "lodash";
import { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import { Controller, useForm } from "react-hook-form";
import { createApi } from "unsplash-js";
import Masonry from "@mui/lab/Masonry";
import { SearchImgContext } from "../../Context/searchImgContext";
import { useContext } from "react";
import { GetProfilesDetails } from "../../services/query/get-profile-details";
import { useParams } from "react-router-dom";

const unsplash = new createApi({
  accessKey: process.env.REACT_APP_UNSPLASH_ACCESS_KEY,
});

const UnsplashSearcher = ({ closeModal }) => {
  const [unsplashPhoto, setUnsplashPhotos] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [limit, setLimit] = useState(6);
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      query: "desert",
    },
  });

  const [imgUrl, setImgUrl] = useContext(SearchImgContext);
  useEffect(() => {
    handleSearch("desert");
  }, []);

  const handleSearch = async (value) => {
    setIsFetching(true);
    const response = await unsplash.search.getPhotos({
      query: value?.search_value || value,
      page: 1,
      perPage: limit,
      color: "green",
      orientation: "portrait",
    });
    setUnsplashPhotos(response?.response);
    setLimit(6);
    if (get(response, "type") === "success") {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    (async () => {
      if (limit !== 6) {
        const response = await unsplash.search.getPhotos({
          query: watch("search_value"),
          page: 1,
          perPage: limit,
          color: "green",
          orientation: "portrait",
        });
        setUnsplashPhotos(response?.response);
      }
    })();
  }, [limit, watch]);

  const { username } = useParams();

  const profile = GetProfilesDetails(username);

  return (
    <Stack>
      <form onSubmit={handleSubmit(handleSearch)}>
        <Stack direction="row" sx={{ mb: 5 }}>
          <Controller
            control={control}
            name="search_value"
            render={({ field }) => (
              <TextField {...field} fullWidth variant="outlined" />
            )}
          />
          <Button type="submit" variant="outlined">
            Search
          </Button>
        </Stack>
      </form>

      {!isFetching ? (
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Masonry columns={3} spacing={2}>
            {get(unsplashPhoto, "results", []).map((item) => (
              <Box onClick={closeModal} style={{ maxWidth: "100%" }}>
                <div onClick={() => setImgUrl(item?.urls?.full)}>
                  <img
                    //  onClick={imgFunction(imgClickState)}
                    src={get(item, "urls.regular")}
                    alt={get(item, "alt_description")}
                    width="100%"
                  />
                </div>
              </Box>
            ))}
          </Masonry>
          {/* <Button
            type="button"
            variant="outlined"
            onClick={() => setLimit(limit + 5)}
          >
            Load more
          </Button> */}
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <ReactLoading type="bars" color="#6036b2" height={60} width={60} />
        </Box>
      )}
    </Stack>
  );
};
export default UnsplashSearcher;
