import "./Forget.css";
import emailIcon from "../../../src/Assets/Img/emailIcon.png";
import blockIcon from "../../../src/Assets/Img/blockIcon.png";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import {
  useForgetPasswordSendCode,
  useForgetPasswordSendEmail,
  useNewPassword,
} from "../../services/mutations/reset-password";

const Forget = () => {
  const [email, setEmail] = useState("");
  const history = useNavigate();
  const [code, setCode] = useState("");
  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const [visible, setVisible] = useState(false);
  const [hidden, setHidden] = useState(false);
  const forgetPasswordSendEmail = useForgetPasswordSendEmail();
  const forgetPasswordSendCode = useForgetPasswordSendCode();
  const { mutate } = useNewPassword();
 
  const changeForgetPassword = async (e) => {
    e.preventDefault();
    forgetPasswordSendEmail.mutate(
      { email: email },
      {
        onSuccess: () => {
          setVisible(true);
        },
        onError: (err) => {
          err?.response?.data?.message &&
            toast.error(err?.response?.data?.message, {
              position: "top-right",
            });
        },
      }
    );
  };

  const changeCodeForgetPage = async (e) => {
    e.preventDefault();
    forgetPasswordSendCode.mutate(
      {
        confirm_code: code,
      },
      {
        onSuccess: () => {
          setHidden(true);
        },
        onError: (error) => {
           toast.error(error?.response?.data?.message, {
            position: "top-right",
          });
        },
      }
    );
  };

  const changeComparePassword = async (e) => {
    e.preventDefault();
    mutate(
      { email, password1, password2 },
      {
        onSuccess: () => {
          toast.success("Parol o'zgartirildi!");
          history("/loginpage");
        },
        onError: (res) => {
          res?.response?.data?.data?.password1 &&
            toast.error(res?.response?.data?.data?.password1[0], {
              position: "top-right",
            });
          res?.response?.data?.data?.password2 &&
            toast.error(res?.response?.data?.data?.password2[0], {
              position: "top-right",
            });
          res?.response?.data?.message &&
            toast.error(res?.response?.data?.message, {
              position: "top-right",
            });
        },
      }
    );
  };

  return (
    <section className="register">
      <ToastContainer
        position="top-left"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
      />
      {!visible ? (
        <div className="container container-top">
          <h2 className="register-title">Parolni Unutdingizmi?</h2>
          <p className="register-text">
            Agar parolni unutgan bo'lsangiz, parolingizni tiklang!{" "}
          </p>
          <form className="register-form" onSubmit={changeForgetPassword}>
            <div className="register-box">
              <label className="register-label">Elektron pochta</label>
              <span>
                <img src={emailIcon} alt="" className="register-icon" />
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="register-input"
                  required
                  placeholder="email@gmail.com"
                />
              </span>
            </div>

            <button
              type="submit"
              id="forgetBtn"
              className="register-btn"
              style={{ padding: "8px 5px" }}
            >
              Qabul qilish
            </button>
          </form>
        </div>
      ) : (
        !hidden && (
          <div className="container container-bottom">
            <h2 className="register-title">Parolni Unutdingizmi?</h2>
            <p className="register-text">
              Biz sizning elektron pochtangizga tasdiqlash kodi bilan xat
              yubordik!
            </p>
            <form className="register-form" onSubmit={changeCodeForgetPage}>
              <div className="register-box">
                <label className="register-label">Verification Code</label>
                <span>
                  <input
                    type="text"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                    className="register-input"
                    required
                    maxlength={6}
                    minlength={6}
                    placeholder="EX: 123456"
                  />
                </span>
              </div>
              <button
                type="submit"
                style={{ padding: "8px 5px" }}
                className="register-btn"
                id="codeBtn"
              >
                Qabul qilish
              </button>
            </form>
          </div>
        )
      )}

      {hidden && (
        <div className="container container-footer">
          <h2 className="register-title">Parolni Unutdingizmi?</h2>
          <p className="register-text">
            Hisobingizga kirish uchun yangi parolni o'rnating!{" "}
          </p>
          <form className="register-form" onSubmit={changeComparePassword}>
            <div className="register-box">
              <label className="register-label">Yangi parolni kiriting</label>
              <span>
                <img src={blockIcon} alt="" className="register-icon" />
                <input
                  value={password1}
                  onChange={(e) => setPassword1(e.target.value)}
                  type="password"
                  className="register-input"
                  required
                  placeholder="**********"
                />
              </span>
            </div>
            <div className="register-box">
              <label className="register-label">Qayta yangi parol</label>
              <span>
                <img src={blockIcon} alt="" className="register-icon" />
                <input
                  type="password"
                  value={password2}
                  onChange={(e) => setPassword2(e.target.value)}
                  className="register-input"
                  required
                  placeholder="**********"
                />
              </span>
            </div>
            <button
              // onClick={updateUser}
              className="register-btn"
              type="submit"
            >
              Tasdiqlash
            </button>
          </form>
        </div>
      )}
    </section>
  );
};

export default Forget;
