import "./Dashboard.css";
import exitPic from "../../Assets/Img/exit.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ExitModal from "../ExitModal/ExitModal";
import React, { useState  } from "react";
import PropTypes from "prop-types";
import defaultImg from "../../Assets/default.svg";
import { useTheme } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import arrow from "../../Assets/Img/arow.png";
import Account from "../../Assets/SVG/Account";
import Murojaat from "../../Assets/SVG/Murojaat";
import Analitika from "../../Assets/SVG/Analitika";
import Profile from "../../Assets/SVG/Profile";
import { get } from "lodash";
import { GetUserInfo } from "../../services/query/get-user-info";

function ModalTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`action-tabpanel-${index}`}
      aria-labelledby={`action-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </Typography>
  );
}

ModalTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

 

const Dashboard = ({ profileLists, username }) => {
    const [value, setValue] = React.useState(0);
    const { pathname } = useLocation();
    const [premiumModalOpen, setPremiumModalOpen] = useState(false);
    const [show, setShow] = useState(false);
 
 

  function openPremiumModal() {
    setPremiumModalOpen(!premiumModalOpen);
  }
 
  const handleOpen = () => {
    setShow(!show); // Toggle accordion
  };

  /////////////////////////////////////////////////////////

  const history = useNavigate();
  const theme = useTheme();

  const handeleProfileSelection = (singleProfile) => {
    history(`/${get(singleProfile, "username")}`);
   };
 
  const filteredSingleProfile = profileLists?.filter(
    (listItem) => listItem?.username === username
  );
  const logout = () => {
    localStorage.clear();
    history("/loginpage");
    window.location.reload();
  };

  const activeLinkStyle = {
    background: "#f5f6fa",
    borderRadius: "4px",
    color: " #512da8",
  };
  const getUserInfo = GetUserInfo(localStorage.getItem("id"));
 
   return (
    <section className="dashboard" style={{ background: "#fff" }}>
      <div className="dashboard-top">
        <div className="dashboard-image-wrapper">
          <img
            src={
              `https://api-toplink.uz${
                get(filteredSingleProfile, "[0].profile_img") ||
                get(profileLists, "[0].profile_img")
              }` || defaultImg
            }
            alt=""
            className="dashboard-avatar"
          />
        </div>
        <div className="dashboard-box" onClick={handleOpen}>
          <h2 className="dashboard-name">
            {get(profileLists, "[0].username")}
          </h2>
          <button className="dashboard__sign">
            {show ? (
              <i className="fa fa-chevron-up" aria-hidden="true"></i>
            ) : (
              <i className="fa fa-chevron-down" aria-hidden="true"></i>
            )}
          </button>
        </div>

        {show && (
          <div className="dashboard-scroll">
            <div className="accordian-body">
            {
             
             get(getUserInfo, "data.status") === "BIZNES" &&  
             get(profileLists, "length")===5 ? (
              ""
             )
             :
             (get(getUserInfo, "data.status") === "PREMIUM" &&  get(profileLists, "length")===3)? (
              <Link
              className="dashboard__links"
              to={`/${username}/modal-pro-page`}>
              <img src={arrow} className="dashboard__img" alt="we" />
              <span>Qo'shish</span>
            </Link>
             )
             :
             (get(getUserInfo, "data.status") === "FREE" &&  get(profileLists, "length")===1)? (
              <Link
              className="dashboard__links"
              to={`/${username}/modal-pro-page`}>
              <img src={arrow} className="dashboard__img" alt="we" />
              <span>Qo'shish</span>
            </Link>
             )
             :
              <Link
                className="dashboard__links"
                to={`/${username}/user-profile`}>
                <img src={arrow} className="dashboard__img" alt="" />
                <span>Qo'shish</span>
              </Link>
}
            </div>
            {get(profileLists, "length") > 0 &&
              profileLists?.map(
                (item) =>
                  get(item, "username") !== username && (
                    <Link
                      to={`/${get(item, "username")}`}
                      key={get(item, "id")}
                    >
                      <div
                        className="profile-list"
                        onClick={() => handeleProfileSelection(item)}
                      >
                        <div className="image-wrapper">
                          <img
                            src={
                              `https://api-toplink.uz${get(
                                item,
                                "profile_img"
                              )}` || defaultImg
                            }
                            alt={get(item, "username")}
                          />
                        </div>
                        <span>{get(item, "username")}</span>
                      </div>
                    </Link>
                  )
              )}
          </div>
        )}
      </div>
      <ul className="dashboard-list">
        <li
          className="dashboard-item"
          style={pathname === `/${username}` ? activeLinkStyle : {}}
        >
          <Link className="dashboard-link" to={`/${username}`}>
            <Profile />
            Profil
          </Link>
        </li>
        <li
          className="dashboard-item"
          style={pathname?.includes("analytic") ? activeLinkStyle : {}}
        >
          <Link className="dashboard-link" to={`${username}/analytic`}>
            <Analitika />
            Analitika
          </Link>
        </li>
        <li
          className="dashboard-item"
          style={pathname?.includes("refertofriend") ? activeLinkStyle : {}}
        >
          <Link className="dashboard-link" to={`${username}/refertofriend`}>
            <Murojaat />
            Do’stlarga murojat
          </Link>
        </li>
        <li
          className="dashboard-item"
          style={pathname?.includes("account") ? activeLinkStyle : {}}
        >
          <Link className="dashboard-link" to={`${username}/account`}>
            <Account />
            Akkount
          </Link>
        </li>
        <li className="dashboard-item">
          <Link
            className="dashboard-link"
            onClick={() => openPremiumModal()}
            to="#"
          >
            <img src={exitPic} alt="" className="dashboard-pic" />
            <p className="dashboard-text dashboard-red">Chiqish</p>
          </Link>
        </li>
        <li className="dashboard-item">
          
          <Link  className="dashboard-btn"  to={`${username}/modal-pro-page`} >
            Yangilash
          </Link>
         
        </li>
      </ul>
      <ExitModal
        show={premiumModalOpen}
        className="exit-modal"
        contentLabel="Example Modal"
      >
        <button
          onClick={() => setPremiumModalOpen()}
          className="exit-close-menu"
        >
          &times;
        </button>
        <div className="exit-header">
          <h4>Chiqish</h4>
          <p>
            Chiqishni xohlaysizmi ? Hisobingizdan chiqib ketsangiz, profilingiz
            va barcha ma’lumotlaringizni qayta kiritishingiz kerak bo‘ladi
          </p>
        </div>
        <div className="exit-footer">
          <button onClick={() => setPremiumModalOpen()}>Yo'q </button>
          <button onClick={logout}>Ha</button>
        </div>
      </ExitModal> 
    </section>
  );
};

export default Dashboard;
