import React from "react";
import { get } from "lodash";
import { ChangeCustomPutPic } from "../../../services/mutations/change-custom-put-pic";
import { buttonsCustomized } from "../../../config/static-data";
import { GetCustomDetail } from "../../../services/query/get-custom-detail";
import { useForm } from "react-hook-form";
import { GetCustom } from "../../../services/query/get-custom";
import { ToastContainer, toast } from "react-toastify";
import { GetUserInfo } from "../../../services/query/get-user-info";
import { Link, useParams } from "react-router-dom";

const ButtonCustomize = (props) => {
  const { handleSubmit } = useForm();
  let profileId = get(props, "profile.data[0].id");
  const getCustomId = GetCustomDetail(profileId);
  const customId = get(getCustomId, "data[0].id");
  const { mutate } = ChangeCustomPutPic(customId);
  const custom = GetCustom(customId);
  const [selectedButton, setSelectedButton] = React.useState();
  const handleChangeButtonStyle = () => {
    const newValues = {
      profile: profileId,
      button_shape: selectedButton,
    };
    mutate(
      { ...newValues },
      {
        onSuccess: () => {
          toast.success("Button muvaffaqiyatli tahrirlandi!", {
            position: "top-right",
          });
        },
      }
    );
  };
  const { username } = useParams();

  const getUserInfo = GetUserInfo(localStorage.getItem("id"));

  return (
    <div className="special-biznes">
      {get(getUserInfo, "data.status") === "BIZNES" ? (
        <div className="special-box special-box1">
        <ToastContainer />
        <div className="special-fon">Buttonlar</div>
        <div className="special-card special-buttons">
          <form className="img-form">
            <ul className="button-list">
              {buttonsCustomized.map((item) => (
                <li key={item.name} className="button-item">
                  <button
                    type="button"
                    value={item.name}
                    onClick={(e) => setSelectedButton(e.target?.value)}
                    defaultValue={custom?.data?.button_shape}
                    className={
                      selectedButton === item.name ? selectedButton : ""
                    }
                  >
                    Link matni
                  </button>
                </li>
              ))}
            </ul>
            <button
              type="button"
              className="color-btn button-btn" style={{cursor: "pointer"}}
              onClick={handleSubmit(handleChangeButtonStyle)}
            >
              Yaratish
            </button>
          </form>
        </div>
      </div>
      ) : (
        <>
        <div
          className="special-box special-box1 biznes-buttons"
          // style={{ filter: `blur(4px)` }}
        >
          <ToastContainer />
          <div className="special-fon">Buttonlar</div>
          <div className="special-card special-buttons">
            <form className="img-form">
              <ul className="button-list">
                {buttonsCustomized.map((item) => (
                  <li key={item.name} className="button-item">
                    <button
                      type="button"
                      value={item.name}
                      onClick={(e) => setSelectedButton(e.target?.value)}
                      defaultValue={custom?.data?.button_shape}
                      className={
                        selectedButton === item.name ? selectedButton : ""
                      }
                    >
                      Link matni
                    </button>
                  </li>
                ))}
              </ul>
              <button
                type="button"
                className="color-btn button-btn"
                onClick={handleSubmit(handleChangeButtonStyle)}
              >
                Yaratish
              </button>
            </form>
          </div>
        </div>
        <div className="biznes-box">
          <p>Ushbu funksiya uchun Biznesga yangilang!</p>
          <Link className="pro-link" to={`/${username}/modal-pro-page`}>
            Hozir yangilang
          </Link>
        </div>
      </>

      )}
    </div>
  );
};

export default ButtonCustomize;
