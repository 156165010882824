import { useMutation } from "react-query";
import request from "../requests";

export const useNewPassword = () =>
  useMutation("password-reset-confirm", (data) =>
    request.public
      .post(`/v1/accounts/password-reset-confirm/`, data)
      .then((res) => res.data)
  );
export const useForgetPasswordSendEmail = () =>
  useMutation("password-reset", (data) =>
    request.public
      .post(`/v1/accounts/password-reset/`, data)
      .then((res) => res.data)
  );
export const useForgetPasswordSendCode = () =>
  useMutation("password-reset-check", (data) =>
    request.public
      .post(`/v1/accounts/password-reset-check/`, data)
      .then((res) => res.data)
  );
