import React, { useState, useEffect } from "react";
import { get } from "lodash";
import { useForm } from "react-hook-form";
import Modal from "react-modal";
import { ChangeCustomPutPic } from "../../../services/mutations/change-custom-put-pic";
import UnsplashSearcher from "..";
import { useContext } from "react";
import { SearchImgContext } from "../../../Context/searchImgContext";
import { Link, useParams } from "react-router-dom";
import { GetCustomDetail } from "../../../services/query/get-custom-detail";
import { ChangeCustomPutUrl } from "./../../../services/mutations/change-custom-put-url";
import { GetCustom } from "../../../services/query/get-custom";
import { ToastContainer, toast } from "react-toastify";
import { GetUserInfo } from "../../../services/query/get-user-info";

const BackgroundChanger = (props) => {
  let subtitle;
  const [modalIsOpen, setIsOpen] = useState(false);
  const [imgUrl, setImgUrl] = useContext(SearchImgContext);
  const [localImage, setLocalImage] = useState("");
  const { username } = useParams();
  let profileId = get(props, "profile.data[0].id");
  const getCustomId = GetCustomDetail(profileId);
  const customId = get(getCustomId, "data[0].id");
  const { mutate } = ChangeCustomPutPic(customId);
  const mutateUrl = ChangeCustomPutUrl(customId).mutate;
  const custom = GetCustom(customId);
  const { register, handleSubmit, watch } = useForm({
    defaultValues: {
      blur: 0,
      opacity: 100,
      picture: custom?.data?.picture,
    },
  });

  /********************* put pic       ******************* */

  const handelChangeBackground = (values) => {
    const bodyFormData = new FormData();
    bodyFormData.append("bacground_picture", localImage);
    bodyFormData.append("profile", get(props, "profile.data[0].id"));
    bodyFormData.append("opacity", watch("opacity"));
    bodyFormData.append("blur", watch("blur"));
    mutate(
      bodyFormData,
      { ...values },
      {
        onSuccess: () => {
          toast.success("Rasmlar muvaffaqiyatli tahrirlandi!", {
            position: "top-right",
          });
        },
      }
    );
  };

  /***********************  put url   ********************* */

  const handelChangeUrl = () => {
    const bodyFormData = new FormData();
    bodyFormData.append("profile", get(props, "profile.data[0].id"));
    bodyFormData.append("bacground_image_url  ", imgUrl);
    bodyFormData.append("opacity", watch("opacity"));
    bodyFormData.append("blur", watch("blur"));
    mutateUrl(bodyFormData, {
      onSuccess: () => {
        toast.success("Rasmlar muvaffaqiyatli tahrirlandi!", {
          position: "top-right",
        });
      },
    });
  };

  const handleSelectLocaleImage = (e) => {
    e.preventDefault();
    setLocalImage(e.target.files[0]);
    setImgUrl("");
  };

  useEffect(() => {
    if (imgUrl.length > 0) setLocalImage("");
  }, [imgUrl]);

  /******************** modal functions         */

  function openModal() {
    setIsOpen(true);
  }
  function afterOpenModal() {
    subtitle.style.color = "#f00";
  }
  function closeModal() {
    setIsOpen(false);
  }

  const handelChangeFunct = () => {
    if (imgUrl.length > 0) {
      handelChangeUrl();
    } else if (localImage) {
      handelChangeBackground();
    }
  };

  const getUserInfo = GetUserInfo(localStorage.getItem("id"));

  return (
    <div className="special-biznes">
      {get(getUserInfo, "data.status") === "BIZNES" ? (
        <div className="special-box ">
        <ToastContainer />
        <div className="special-fon">Orqa fon</div>
        <form
          className="special-card special-forms"
          encType="multipart/form-data"
          onSubmit={handleSubmit(handelChangeFunct)}
        >
          <div style={{ width: "100%" }}>
            <div className="special-find">
              <div
                className="img-box"
                style={{
                  backgroundSize: `cover`,
                  filter: `blur(${watch(`blur`)}px)`,
                  opacity: `${watch("opacity") / 100}`,
                  backgroundRepeat: `no-repeat`,
                }}
              >
                {localImage && URL.createObjectURL(localImage) ? (
                  <img
                    src={URL.createObjectURL(localImage)}
                    width={262}
                    height={206}
                    alt="backimg"
                  />
                ) : imgUrl.length > 0 ? (
                  <img src={imgUrl} width={262} height={206} alt="backimg" />
                ) : ( 
                  <img src={custom?.data?.picture} width={262} alt="backimg" height={206} />
                )}
              </div>
              <div className="img-blok">
                <button
                  type="button"
                  onClick={openModal}
                  className="special-search"
                >
                  Fonni qidiring
                </button>
                <span>
                  <label className="special-upload" htmlFor="upload-photo">
                    Kompyuterdan yuklash
                  </label>
                  <input
                    type="file"
                    id="upload-photo"
                    onChange={handleSelectLocaleImage}
                    accept="image/png, image/gif, image/jpeg"
                  />
                </span>
              </div>
            </div>
            <div className="special-range">
              <div className="special-blur">
                <p>Blur</p>
                <input
                  type="range"
                  {...register("blur")}
                  className="input-range"
                />
              </div>
              <div className="special-blur special-blurs">
                <p>Opacity</p>
                <input
                  type="range"
                  {...register("opacity")}
                  className="input-range "
                />
              </div>
            </div>
          </div>
          <button type="submit" className="color-btn img-btn">
            Yaratish
          </button>
        </form>
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          contentLabel="Example Modal"
        >
          <UnsplashSearcher {...{ closeModal }} />
        </Modal>
      </div>
      ) : (
        <>
        <div
          className="special-box biznes-img"
          style={{ filter: `blur(5px)` }}
        >
          <ToastContainer />
          <div className="special-fon">Orqa fon</div>
          <form
            className="special-card special-forms"
            encType="multipart/form-data"
            onSubmit={handleSubmit(handelChangeFunct)}
          >
            <div style={{ width: "100%" }}>
              <div className="special-find">
                <div
                  className="img-box"
                  style={{
                    backgroundSize: `cover`,
                    filter: `blur(${watch(`blur`)}px)`,
                    opacity: `${watch("opacity") / 100}`,
                    backgroundRepeat: `no-repeat`,
                  }}
                >
                  {localImage && URL.createObjectURL(localImage) ? (
                    <img
                      src={URL.createObjectURL(localImage)}
                      width={262}
                      height={206} alt="backimg"
                    />
                  ) : imgUrl.length > 0 ? (
                    <img src={imgUrl} width={262} height={206} alt="backimg" />
                  ) : (
                    <img
                      src={custom?.data?.picture}
                      width={262}
                      height={206}
                      alt="backimg"
                    />
                  )}
                </div>
                <div className="img-blok">
                  <button
                    type="button"
                    onClick={openModal}
                    className="special-search"
                  >
                    Fonni qidiring
                  </button>
                  <span>
                    <label className="special-upload" htmlFor="upload-photo">
                      Kompyuterdan yuklash
                    </label>
                    <input
                      type="file"
                      id="upload-photo"
                      onChange={handleSelectLocaleImage}
                      accept="image/png, image/gif, image/jpeg"
                    />
                  </span>
                </div>
              </div>
              <div className="special-range">
                <div className="special-blur">
                  <p>Blur</p>
                  <input
                    type="range"
                    {...register("blur")}
                    className="input-range"
                  />
                </div>
                <div className="special-blur special-blurs">
                  <p>Opacity</p>
                  <input
                    type="range"
                    {...register("opacity")}
                    className="input-range "
                  />
                </div>
              </div>
            </div>
            <button type="submit" className="color-btn img-btn">
              Yaratish
            </button>
          </form>
          <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            contentLabel="Example Modal"
          >
            <UnsplashSearcher {...{ closeModal }} />
          </Modal>
        </div>

        <div className="biznes-box">
          <p>Ushbu funksiya uchun Biznesga yangilang!</p>
          <Link className="pro-link" to={`/${username}/modal-pro-page`}>
            Hozir yangilang
          </Link>
        </div>
      </>

      )}
    </div>
  );
};

export default BackgroundChanger;
