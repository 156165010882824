import { Stack } from "@mui/material";
import "../../App.css";
import Settings from "../../Components/Settings/Settings";
const SettingsPage = () => (
  <Stack>
    {/* <UnsplashSearcher /> */}
    <Settings />
  </Stack>
);

export default SettingsPage;
