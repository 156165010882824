import React from "react";
import { useState, useContext } from "react";
import { AnimationContext } from "../../../Context/AnimationContext";
import { useForm } from "react-hook-form";
import { GetAnimation } from "../../../services/query/get-animation";
 import { ToastContainer, toast } from "react-toastify";
import ReactLoading from "react-loading";
 import { useLinkDelete } from "../../../services/mutations/delete-link";
 import { EditAnimationPut } from "../../../services/mutations/edit-put-animation";

 function EditAnimation({ linkId }) {
  const { handleSubmit, reset, register, watch } = useForm();
  const orgData = [
    { id: 1, name: "disabled" },
    { id: 2, name: "jello" },
    { id: 3, name: "bounce" },
    { id: 4, name: "pulse" },
    { id: 5, name: "shake" },
    { id: 6, name: "vibrate" },
    { id: 7, name: "wobble" },
  ];
   const [selectAnimated, setSelectedAnimated] = useState();
  const [anime, setAnime] = useState(null);
  const [animation, setAnimation] = useContext(AnimationContext);
  const { data, isFetching } = GetAnimation(linkId);
  const { mutate, isLoading } = EditAnimationPut(linkId);
   const selectAnimatedChange = (event) => {
    setSelectedAnimated(event.target.value);
    setAnimation(event.target.value);
    localStorage.setItem("animation", event.target.value);
    setAnime(
      <button type="button" className={`animation-btn ${event.target.value}`}>
        Preview
      </button>
    );
  };
 
  const changeLinkGetUpdate = async (values) => {
    const requestData = {
      animation: animation,
     };
     mutate(requestData, {
      onSuccess: () => {
        toast.success("Animatsiya muvaffaqiyatli tahrirlandi!", {
          position: "top-right",
        });
        setIsChanged(!isChanged);
        // window.location.reload();
      },
    });
  };

  ///****************************  link delete  ********************** */
  const [isChanged, setIsChanged] = useState(false);
  const deleteLink = useLinkDelete(linkId);
  const changeLinkDelete = async () => {
    deleteLink?.mutate("", {
      onSuccess: () => {
        toast.success("Link muvaffaqiyatli o'chirildi!", {
          position: "top-right",
        });
        setIsChanged(!isChanged);
        window.location.reload();
      },
    });
  };
  
  return (
    <div className="animation">
      <form className="add-form">
        <div className="add-box">
          <label htmlFor="" className="add-label">
            Animation
          </label>
          <select className="animation-select" value={selectAnimated} onChange={selectAnimatedChange} required>
            {orgData.map((row) => (
              <option
                selected={row.name === data?.animation.toLowerCase()}
                key={row.id}
              >
                {row.name}
              </option>
            ))}
          </select>
          {selectAnimated && anime}
        </div>
        <div className="add-footer">
          <button className="add-cancel" type="button" onClick={handleSubmit(changeLinkDelete)}>
            O'chirish
          </button>
          <button className="add-create" type="submit" onClick={handleSubmit(changeLinkGetUpdate)}>
            {isLoading ? (
              <ReactLoading
                type="bubbles"
                color="#fff"
                height={40}
                width={40}
              />
            ) : (
              "Yangilash"
            )}
          </button>
        </div>
      </form>
    </div>
  );
}

export default EditAnimation;
