import { useCallback, useContext, useEffect, useState } from "react";
import getCroppedImg from "../utils/get-cropped-img";
import CropperModal from "../utils/cropped-modal";
import defaultImg from "../../../Assets/linkdefaultimg.svg";
import { Skeleton } from "@mui/material";
import ReactLoading from "react-loading";
import { GetProfilesDetails } from "../../../services/query/get-profile-details";
import { useEditProfile } from "../../../services/mutations/edit-profile";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { get } from "lodash";
import { ChangeProfileContext } from "../../../Context/ProfileChangeContext";
import LazyImage from "react-lazy-blur-image";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import "../tabpanel.style"
import { GetThumbnailLinkLists } from "../../../services/query/get-thumbnail";
import { useThumbnailEdit } from "../../../services/mutations/edit-put-thumbnail";
import { useLinkDelete } from "../../../services/mutations/delete-link";
export const ASPECT_RATIO = 3 / 3;
export const CROP_WIDTH = 500;
const EditThumbnail = ({ linkId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [uploadedFile, setUploadedFile] = useState("");
  const [minZoom, setMinZoom] = useState(1);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [imgSrc, setImgSrc] = useState("");
  const [croppedAreaPixels, setCroppedAreaPixels] = useState();
  const { mutate, isLoading } = useThumbnailEdit(linkId);
  const { data, isFetching } = GetThumbnailLinkLists(linkId);
   const [isChangeProfile, setIsChangeProfile] =
    useContext(ChangeProfileContext);

  const { handleSubmit, reset, register, watch } = useForm({
    defaultValues: {
     },
  });

  const [croppedImgSrc, setCroppedImgSrc] = useState();
  const changeThumbnailProfile = async (values) => {
    const formData = new FormData();
    const croppedFile = new File([croppedImgSrc], uploadedFile.name, {
      lastModified: new Date(),
      type: uploadedFile.type,
    });

    if (croppedFile) {
      uploadedFile && formData.append("thumbnail", uploadedFile);
       mutate(formData, {
        onSuccess: () => {
          toast.success("Logo muvaffaqiyatli tahrirlandi!", {
            position: "top-right",
          });
          setIsChangeProfile(!isChangeProfile);
        },
        onError: () => {
          toast.error("Ma'lumotlarni to'g'ri kiritmadingiz", {
            position: "top-right",
          });
        },
      });
    }
  };
  const onMediaLoaded = useCallback((mediaSize) => {
    const { width, height } = mediaSize;
    const mediaAspectRadio = width / height;
    if (mediaAspectRadio > ASPECT_RATIO) {
      const result = CROP_WIDTH / ASPECT_RATIO / height;
      setZoom(result);
      setMinZoom(result);
      return;
    }
    const result = CROP_WIDTH / width;
    setZoom(result);
    setMinZoom(result);
  }, []);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    if (!croppedAreaPixels) return;
    try {
      const croppedImage = await getCroppedImg(imgSrc, croppedAreaPixels);
      setCroppedImgSrc(croppedImage);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, imgSrc]);

  const onFileChange = useCallback(async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      setUploadedFile(e.target.files[0]);
      reader.addEventListener("load", () => {
        if (reader.result) {
          setImgSrc(reader.result.toString() || "");
          setIsOpen(true);
        }
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  }, []);
   useEffect(() => {
    const defaultValues = {
      thumbnail: data?.thumbnail === null ? defaultImg : data?.thumbnail,
    };
    reset(defaultValues);
  }, [data, reset, watch]);
  const [isChanged, setIsChanged] = useState(false);
  const deleteLink = useLinkDelete(linkId);
  const changeLinkDelete = async () => {
    deleteLink?.mutate("", {
      onSuccess: () => {
        toast.success("Link muvaffaqiyatli o'chirildi!", {
          position: "top-right",
        });
        setIsChanged(!isChanged);
        window.location.reload();
      },
    });
  };
   return (
    <form className="edit-form">
      <div className="edit-section"style={{justifyContent:"center", alignItems:"center"}}>
        <div className="edit-left">
          <div
            className="edit__left"
            style={{
              position: "relative",
              width: "140px",
              height: "140px",
            }}
          >
            <CropperModal
              crop={crop}
              setCrop={setCrop}
              zoom={zoom}
              setZoom={setZoom}
              onCropComplete={onCropComplete}
              open={isOpen}
              onClose={() => setIsOpen(false)}
              imgSrc={imgSrc}
              showCroppedImage={showCroppedImage}
              onMediaLoaded={onMediaLoaded}
              minZoom={minZoom}
            />
            {!isFetching ? (
              <div className="image-uploader">
                <LazyImage
                  placeholder={
                    `${data?.thumbnail===null ? defaultImg : data?.thumbnail}` ||
                    defaultImg
                  }
                  uri={
                    `${
                      croppedImgSrc || data?.thumbnail===null ? defaultImg : data?.thumbnail
                    }` || defaultImg
                  }
                  render={(src, style) => (
                    <img
                      src={
                        uploadedFile ? URL.createObjectURL(uploadedFile) : src
                      }
                      className="edit-avatar"
                      alt=""
                      htmlFor="myImage"
                    />
                  )}
                />
                <div className="image-upload-hover">
                  <CloudUploadIcon fontSize="large" sx={{ color: "#fff" }} />
                </div>
                <input
                  type="file"
                  name="myImage"
                  accept="image/png, 
      image/jpeg, image/jpg"
                  style={{
                    opacity: 0,
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%,-50%)",
                    width: "100%",
                    height: "100%",
                  }}
                  onChange={onFileChange}
                />
              </div>
            ) : (
              <Skeleton
                sx={{ backgroundColor: "#f7f7f7" }}
                variant="circular"
                width="130px"
                height="130px"
              />
            )}
          </div>
        </div>
       
      </div>
      <div className="add-footer" style={{width: "100%"}}>
        <button
          className="add-cancel"
          type="button"
          onClick={handleSubmit(changeLinkDelete)}
        >
          O'chirish
        </button>
        <button
          className="add-create"
          type="submit"
          onClick={handleSubmit(changeThumbnailProfile)}
        >
          {isLoading ? (
            <ReactLoading type="bubbles" color="#fff" height={40} width={40} />
          ) : (
            "Yangilash"
          )}
        </button>
      </div>
    </form>
  );
};
export default EditThumbnail;
