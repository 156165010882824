 
import { useQuery } from "react-query";
import request from "../requests";

export const GetYearTarif = () =>
  useQuery(["get-year-tarif"], () =>
    request.public
      .get(`/v1/subscription/tarif-list-year/`)
      .then((res) => res?.data
       )
  );