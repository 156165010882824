import "./Analytic.css";
import { Link, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { GetProfilesDetails } from "../../services/query/get-profile-details";
import { get } from "lodash";
import { GetProfilViews } from "../../services/query/get-profile-views";
import { Stack } from "@mui/material";
import { GetLinkDetails } from "../../services/query/get-link-details";
import { GetUserInfo } from "../../services/query/get-user-info";

const Analytic = () => {
  const { username } = useParams();
  const { data, isFetching } = GetProfilesDetails(username);
  const profileViews = GetProfilViews(username);
  const linkDetails = GetLinkDetails(username);
  const getUserInfo = GetUserInfo(localStorage.getItem("id"))

  const [linkViews, setLinkViews] = useState([]);
  useEffect(() => {
    fetch(`https://api-toplink.uz/api/v1/accounts/link-detail/${username}/`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((views) => {
        setLinkViews([views]);
      });
  }, [username]);
 
 
  return (
    <section className="analytic">
      <div className="analytic-top">
        <div className="analytic-left">
          <img
            src={`https://api-toplink.uz${get(data, "[0].profile_img")}`}
            alt=""
            className="analytic-avatar"
          />
          <div className="analytic-header">
            <h2 className="analytic-name">@{get(data, "[0].username")}</h2>
            <p className="analytic-subname">{get(data, "[0].full_name")}</p>
          </div>
        </div>
        <div className="analytic-right">
          
            <Link  className="analytic-link" to={`/${username}/modal-pro-page`} style={{"color": "white"}}>
            Hozir yangilash
            </Link>
           
        </div>
      </div>
      <div className="analytic-medium">
        <div className="analytic-text">Profilning qisqacha tavsifi</div>
        <div className="analytic-blok">
          <div className="analytic-box">
            {
            get(getUserInfo, "data.status")==="FREE" ? (<div style={{filter: `blur(5px)`, fontSize:"25px"}}>9999</div>) :  (<h2 className="analytic-number" >
            {profileViews?.data?.data?.view_count ? profileViews?.data?.data?.view_count : "0" } </h2>)
          }
          
            <p className="analytic-subtext">Profil ko'rishlar soni</p>
          </div>
          <div className="analytic-box">
          {
            get(getUserInfo, "data.status")==="FREE" ? (<div style={{filter: `blur(5px)`, fontSize:"25px"}}>9999</div>) :  (  <h2 className="analytic-number">
            {profileViews?.data?.total_clicks_for_link?.total_view ? profileViews?.data?.total_clicks_for_link?.total_view : "0"}
          </h2>)
          }
            <p className="analytic-subtext">Barcha linklar ko'rishlar soni</p>
          </div>
        </div>
      </div>
      <div className="analytic-footer">
        <div className="analytic-havola">
          Havolalarni bosishlar statistikasi
        </div>
        <ul className="analytic-list">
          {linkViews[0]?.click_for_one_link?.length>0 ? (
            linkViews[0]?.click_for_one_link?.map((view,i) => (
                 <li className="analytic-item" key={i}>
                  <div className="analytic-links">
                    <span>{view?.icon_name}</span>
                    <a href={`${view?.icon_url}`}>{view?.icon_url}</a>
                  </div>
                  <div className="analytic-count">{view?.view_count}</div>
                </li>
             ))
          ) : (
            <Stack
              fullWidth
              sx={{
                height: "80px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <p style={{ color: "#8898a1" }}>
                Hozircha hech qanday havola yaratilmagan!
              </p>
            </Stack>
          )}
        </ul>
      </div>

    </section>
  );
};

export default Analytic;
