import React from "react";
import { Link, useParams } from "react-router-dom";

export default function LinkProModal() {
    const { username } = useParams();
  return (
    <div className="pro-box">
      <p>
        Ushbu va boshqa ko'plab funksiyalarga kirish uchun Premiumga  yangilang!
      </p>
      <Link className="pro-link" to={`/${username}/modal-pro-page`}>
        Hozir yangilang
      </Link>
    </div>
  );
}

 