 import styled from "styled-components";
 

export const CheckBoxWrapper = styled.div`
  position: relative;
`;

export const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 60px;
  height: 30px;
  border-radius: 15px;
  background: grey;
  cursor: pointer;
  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 25px;
    height: 22px;
    margin: 3px;
    background: #fff;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`;
export const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 62px;
  background: #bada55;
  height: 22px;
  &:checked + ${CheckBoxLabel} {
    background: #bada55;
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 25px;
      height: 22px;
      background: white;
       transition: 0.2s;
       left: calc(100% - 2px);
       transform: translateX(100%);
    }
  }
`;
