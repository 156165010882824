import "./Nav.css";
import LogoImg from "../../Assets/Img/LogoImg.png";
import menuIcon from "../../Assets/Img/menu.png";
import { useEffect, useState } from "react";
import Modal from "react-modal";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DehazeIcon from '@mui/icons-material/Dehaze';
import { GetProfiles } from "../../services/query/get-profiles";
import { get } from "lodash";
const customStyles = {
  content: {
    top: "0% !important",
    left: "84%",
    right: "auto",
    bottom: "auto",
    height: "100vh",
    marginRight: "-100%",
    transform: "translate(-50%, -50%) !important",
  },
};

const Nav = () => {
  let subtitle;
  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }
  const { pathname } = useLocation();
  const token = localStorage.getItem("toplink_token");
  const id = localStorage.getItem("id");
  const history = useNavigate();
  const { data } = GetProfiles(id);
  const clickhandler =()=>{
    // useEffect(() => {
      if (get(data, "length") > 0  && pathname === "/" && token) {
        history(`/${get(data, "[0].username")}`);
      }
    // }, [data, history, pathname]);
  }
  
   return (
    <nav className="nav">
      <div className="container">
        <Link to="/" className="logo-link">
          <img src={LogoImg} alt="" className="logo-img" />
        </Link>
        <ul className="nav-list">
          <li className="nav-item">
            <Link to="/workpage" className="nav-link">
              U qanday ishlaydi
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/featurepage" className="nav-link">
              Xususiyat
            </Link>
          </li>
          
          {get(data, "length") > 0   && token ? 
          <li className="nav-item">
            <button onClick={clickhandler} className="nav-link " style={{cursor: "pointer"}}>
              Profile           
               </button>
          </li>
          :
          <><li className="nav-item">
          <Link to="/loginpage" className="nav-link">
            Kirish
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/register-page" className="nav-link">
            Ro‘yxatdan o‘tish
          </Link>
        </li></>
          }
        </ul>
        <button className="menu-btn" onClick={openModal}>
          <DehazeIcon  style={{color: "#5A33AE", fontSize: "32px", fontWeight: "bolder"}}
           />
        </button>
      </div>

      <div className="navs-modal">
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <ul className="nav-lists">
            <button onClick={closeModal} className="close-menus">
              &times;
            </button>
            <li className="nav-items">
              <Link to="/workpage" className="nav-link">
                U qanday ishlaydi
              </Link>
            </li>
            <li className="nav-items">
              <Link to="/featurepage" className="nav-link">
                Xususiyat
              </Link>
            </li>
          {get(data, "length") > 0   && token ? 
          <li className="nav-item">
            <button onClick={clickhandler} className="nav-link " style={{cursor: "pointer"}}>
              Profile           
               </button>
          </li>
          :
          <><li className="nav-items">
          <Link to="/loginpage" className="nav-link">
            Kirish
          </Link>
        </li>
        <li className="nav-items">
          <Link to="/register-page" className="nav-link">
            Ro‘yxatdan o‘tish
          </Link>
        </li></>
          }
          </ul>
        </Modal>
      </div>
    </nav>
  );
};

export default Nav;
